<template>
    <div class="availability_sidebar d-flex align-items-center position-relative availability_calendar_contingent-sticky">
        <div class="availability_sidebar-select">
            <b-form-checkbox :unchecked-value="false" :value="true"  @change="updateSelected" v-model="selected" size="sm" class="mt-2 pr-0 mr-0" ></b-form-checkbox>
        </div>
        <span @click="handleClick"
              v-if="checkPermission(R_PROPERTY_CALENDAR_DEV)"
              :id="tooltipTarget"
              class="availability_sidebar-contigent-name "
              :class="classList"><span @click="redirectToPropertyCalendar"class="availability_calendar--poiner">

         <div @click="redirectToPropertyCalendar" class="mt-1 action_button justify-content-start availability_calendar_numeric-indicator" :style="!showPropertyItem? 'height: 0.24rem' : ''" style="font-weight: normal; margin-top:9px!important;">
               <template v-if="showPropertyItem">
                          {{ property.name }}
               </template>

        </div>
            <span class="action_button" @click="redirectToPropertyUnitCalendar">
                    {{ shortUnitName }} ({{ Object.keys(unit.contigent).length + 1 }})
            </span>
        </span>
        </span>
        <div v-else class="availability_sidebar-contigent-name">
            <span @click="handleClick"
                  :id="tooltipTarget"
                  :class="classList"><span class="availability_calendar--poiner">

         <div class="mt-1 mb-1 justify-content-start availability_calendar_numeric-indicator" :style="!showPropertyItem? 'height: 0.24rem' : ''" style="font-weight: normal; margin-top:9px!important;">
               <template v-if="showPropertyItem">
                          {{ property.name }}
               </template>

        </div>
            <span>
                    {{ shortUnitName }} ({{ Object.keys(unit.contigent).length + 1 }})
            </span>
        </span>
        </span>
        </div>
        <i v-if="!display"
           :id="'tooltip-target_help-'+unit.id+'_'+dateStart()" class=" fa fa-question-circle-o ml-1 pull-right tooltip-help  mr-2 mb-4"></i>
        <b-tooltip :target="'tooltip-target_help-'+unit.id+'_'+dateStart()" v-if="!display" custom-class="my-tooltip-class" >
            <template v-if="this.unit && this.unit.hasOwnProperty('los') && this.unit.los && this.unit.los.length > 1 && defaultLosLabel">{{$t("STAY").toLocaleLowerCase() }} {{defaultLosLabel}}</template>

            <template v-if="this.unit && this.unit.hasOwnProperty('los') && this.unit.los && this.unit.los.length > 1 &&  defaultLosLabel"> / </template>{{$t("OCCUPANCY_PERSONS").toLocaleLowerCase()}}
            {{defaultOccupancyLabel}}
            <template v-if="showPlatformFeeDisclaimer && unit.platform_fee_set">
                <b-row>
                    <b-col>
                        {{ $t("BOOKING_FEE_CALENDAR_DISCLAIMER") }}
                    </b-col>
                </b-row>
            </template>
        </b-tooltip>
        <b-tooltip v-if="showShortName" placement="bottom" :target="tooltipTarget" triggers="hover">
            {{unit && unit.hasOwnProperty('unit_type') && unit.unit_type ? unit.unit_type.name : '' }}
        </b-tooltip>
    </div>
</template>

<script>
import router from "@/router";
import routeName from "@/router/routeNames";
import {EventBus, GE_CALENDAR_SELECT_UNIT} from "@/shared/EventBus";
import {R_PROPERTY_CALENDAR_DEV} from "@/shared/route_permission";
import {MULTI_UNIT_VIEW} from "@/shared/constants";
import moment from "moment";

export default {
    name: "PropertyCalendarSidebarContigentItemTest",
    props: {
        display: {
            type: Number,
            default: 0
        },
        unit: {
            type: Object
        },
        dateRange: {
            type: Object
        },
        property: {
            type: Object
        },
        selectedUnits: {
            type: Array,
            default() {
                return []
            }
        },
        showPropertyItem: {
            type: Boolean,
            default: true
        },
        showPlatformFeeDisclaimer: {
            type: Boolean,
            default: false,
        },
    },

    computed: {

        classList() {
            if (this.showPropertyItem) {
                return {
                    "availability_calendar--dropdown-up": this.show,
                    "availability_calendar--dropdown-down": !this.show,
                }
            } else {
                return {
                    "availability_calendar--dropdown-up1": this.show,
                    "availability_calendar--dropdown-down1": !this.show,
                }
            }

        },
        showShortName() {
            return this.unit.unit_type.name.length > this.shortNameCharacters
        },
        shortUnitName() {
            if (this.showShortName) {
                return this.unit.unit_type.name.substr(0, this.shortNameCharacters) + '...'
            }
            return this.unit.unit_type.name
        },
        tooltipTarget() {
            return `tooltip_full_unit_type_name-${this.unit.id}`
        },
        defaultLosLabel() {
            if (Object.keys(this.unit.los).length > 1) {
                if (!this.unit.los.max_stay) {
                    return this.unit.los.min_stay + '+'
                }
                if (this.unit.los.min_stay === this.unit.los.max_stay) {
                    return this.unit.los.min_stay
                }
                if (this.unit.los.min_stay !== this.unit.los.max_stay) {
                    return this.unit.los.min_stay + '-' + this.unit.los.max_stay
                }
            }

            return null
        },
        defaultOccupancyLabel() {
            if (Object.keys(this.unit.occupancy).length > 0) {

                if (!this.unit.occupancy.max_guests) {
                    return this.unit.occupancy.min_guests + '+'
                }
                if (this.unit.occupancy.min_guests === this.unit.occupancy.max_guests) {
                    return this.unit.occupancy.min_guests
                }
                if (this.unit.occupancy.min_guests !== this.unit.occupancy.max_guests) {
                    return this.unit.occupancy.min_guests + '-' + this.unit.occupancy.max_guests
                }

            }
            return null
        },

    },
    data() {
        return {
            show: false,
            shortNameCharacters: 50,
            shortMonthlyNameCharacters: 12,
            selected: false,
            R_PROPERTY_CALENDAR_DEV, MULTI_UNIT_VIEW
        }
    },
    methods: {
        dateStart() {
            return moment(this.dateRange.start).startOf('month').format("YYYY-MM-DD")
        },
        updateSelected(value) {
            this.selected = value
            EventBus.$emit(GE_CALENDAR_SELECT_UNIT, {unit: this.unit.id, value, contingent_item: false})
            if (this.unit.hasOwnProperty('contigent') && this.unit.contigent) {
                const contingentUnitIds = Object.keys(this.unit.contigent)
                if (contingentUnitIds.length > 0) {
                    EventBus.$emit(GE_CALENDAR_SELECT_UNIT, {unit: this.unit.id, value, contingent_item: true})
                    contingentUnitIds.forEach(contingentUnitId => {
                        EventBus.$emit(GE_CALENDAR_SELECT_UNIT, {unit: Number(contingentUnitId), value, contingent_item: true})
                    })
                }
            }
        },
        handleClick() {
            this.show = !this.show
            this.$emit('show', this.show)
        },
        redirectToPropertyCalendar() {
            let routerData = router.resolve({
                name: routeName.RN_PROPERTY_CALENDAR,
                params: {id: this.property.id},
                query: {start: this.dateRange.start, end: this.dateRange.end, all_unit: true}
            })
            window.open(routerData.href, '_blank')
        },
        redirectToPropertyUnitCalendar() {
            let routerData = router.resolve({
                name: routeName.RN_PROPERTY_CALENDAR,
                params: {id: this.property.id},
                query: {start: this.dateRange.start, end: this.dateRange.end, unit: this.unit.id}
            })
            window.open(routerData.href, '_blank')
        }
    },
    watch: {
        selectedUnits: {
            handler(selectedObjectList) {
                this.selected = selectedObjectList.find(el => el.unit === this.unit.id && el.contingent_item === false) ? true : false
            }, immediate: true,
            deep: true
        },
        selected: {
            handler(value) {
                if (value) {
                    this.show = true
                    this.$emit('show', true)
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
