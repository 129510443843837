<template>
    <div>
        <app-table-v2 :fields="computedFields"
                      :selectable="true"
                      :provider="getData"
                      :default-limit="defaultLimit"
                      :show-limit="showLimit"
                      @busyToggled="toggleBusy"
                      :search-event="SEARCH_EVENT">
            <template v-slot:start_date="data">
                {{data.item.start_date | date}}
            </template>

            <template v-slot:end_date="data">
                {{data.item.end_date | date}}
            </template>

            <template v-slot:actions="data">
                <app-button variant="link" v-if="data.item.downloadable"
                            icon="excel"
                            :show_text="false"
                            @click="downloadXLS(data.item)">
                </app-button>

                <app-button v-if="data.item.invoice.id"
                            variant="link"
                            button_type="pdf"
                            :show_text="false"
                            @click="downloadInvoice(data.item)">
                </app-button>

                <app-button v-if="data.item.status.id !== CALCULATION_STATUS_CANCELED" variant="link"
                            icon="edit"
                            :show_text="false"
                            @click="$emit('showCalculation', data.item.id)">
                </app-button>
            </template>

            <template v-slot:selectedContainer="data">
                <app-button @click="sendModal=true"
                            class="mr-3">
                    {{$t('SEND')}}
                </app-button>

                <b-modal :title="$t('SEND_CALCULATION_DIALOG')" v-model="sendModal">
                    <b-col class="mt-3">
                        <ul>
                            <li v-for="calculation in data.selected">{{calculation.id}} &#8594; {{calculation.owner.name}}</li>
                        </ul>
                    </b-col>

                    <template v-slot:modal-footer>
                        <div class="w-100 d-flex justify-content-between align-items-center">
                            <app-button @click="sendActionEmails(data.selected)" class="mr-3">

                                {{$t('SEND')}}
                            </app-button>

                            <app-button @click="sendModal=false" class="mr-3">

                                {{$t('CANCEL')}}
                            </app-button>
                        </div>
                    </template>
                </b-modal>
            </template>
        </app-table-v2>
    </div>
</template>

<script>
    import {EventBus} from "@/shared/EventBus";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {cloneDeep} from "lodash";
    import AppTableV2 from "@/components/app/AppTableV2";
    import AppButton from "@/components/app/AppButton/AppButton";
    import {
        getOwnerCalculationList,
        groupNotifyCalculation
    } from "@/services/finance/owner_calculation";
    import {fileHelpers} from "@/mixins/shared/helpers";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import {downloadDocumentFromUrl} from "@/services/document";
    import {
        CALCULATION_STATUS_CANCELED,
        CALCULATION_TYPE_OWNER,
        CONTACT_ACTION_CALCULATION_OVERVIEW,
        WORKFLOW_BOOKER_TOOLS
    } from "@/shared/constants";

    export default {
        name: "OwnerCalculationTable",
        components: {AppButton, AppTableV2},
        mixins: [getErrorMessage, fileHelpers],
        props: {
            defaultLimit: {
                type: Number,
                default: 10
            },
            showLimit: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                fields: [
                    {key: 'id', label: this.$t('ID')},
                    {key: 'account_manager.name', label: this.$t('ACCOUNT_MANAGER')},
                    {key: 'start_date', label: this.$t('START')},
                    {key: 'end_date', label: this.$t('END')},
                    {key: 'owner.name', label: this.$t('OWNER')},
                    {key: 'property.name', label: this.$t('PROPERTY')},
                    {key: 'total_f', label: this.$t('TOTAL'), tdClass: 'text-right'},
                    {key: 'status.label', label: this.$t('STATUS')},
                    {key: 'actions', label: '', class: 'text-right'},
                ],
                SEARCH_EVENT: 'getCalculations',
                sendModal: false,
                CALCULATION_STATUS_CANCELED
            }
        },
        computed: {
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
            computedFields() {
                if (this.company.workflow_id === WORKFLOW_BOOKER_TOOLS) {
                    const index = this.fields.findIndex(el => el.key === 'account_manager.name')
                    this.fields.splice(index, 1)
                }
                return this.fields
            }
        },
        methods: {
            getData(req) {
                return getOwnerCalculationList({
                    page: req.currentPage,
                    limit: req.perPage,
                    ...this.filter,
                    company: this.company.id,
                    calculation_type: CALCULATION_TYPE_OWNER,
                    order_by: 'id',
                    order_by_direction: 'desc'
                })
            },
            toggleBusy($event) {
                EventBus.$emit('loading', $event)
            },
            downloadXLS(item) {
                downloadDocumentFromUrl(item.document.route).then(response => {
                    this.handleFileDownload(response, item.id, 'xlsx')
                }, error => this.showErrorMessages(error))
            },
            downloadInvoice(item) {
                downloadDocumentFromUrl(item.invoice.document.route).then(response => {
                    this.handleFileDownload(response, item.id, 'pdf')
                }).catch((error) => {
                    this.showErrorMessages(error)
                })
            },
            sendActionEmails(selected) {
                const request = {
                    calculation: selected.map(el => el.id) || [],
                    actionList: [CONTACT_ACTION_CALCULATION_OVERVIEW],
                }

                this.sendModal = false
                groupNotifyCalculation(request).then(() => {
                    notifySuccess()
                }, error => this.showErrorMessages(error))
            }
        },
        mounted() {
            EventBus.$on('searchCalculations', filter => {
                if (filter) {
                    this.filter = cloneDeep(filter)
                }
                EventBus.$emit(this.SEARCH_EVENT)
            })
        },
        beforeDestroy() {
            EventBus.$off('searchCalculations')
        }
    }
</script>

<style scoped>

</style>
