import http from '../http'
import {DOCUMENT_URL} from '@/services/endpoints'


export function getDocumentList(objectId, documentTypeId, params = {}) {
    return http.request({
        url: DOCUMENT_URL + `/${documentTypeId}/${objectId}`,
        method: 'get',
        params
    })
}

export function updateDocumentList(objectId, documentTypeId, data) {
    return http.request({
        url: DOCUMENT_URL + `/${documentTypeId}/${objectId}`,
        method: 'put',
        data: data
    })
}

export function deleteDocument(objectId, documentTypeId, documentId) {
    return http.request({
        url: DOCUMENT_URL + `/${documentTypeId}/${objectId}/${documentId}`,
        method: 'delete'
    })
}

export function deleteDocumentList(objectId, documentTypeId, listOfIds) {
    return http.request({
        url: DOCUMENT_URL + `/${documentTypeId}/${objectId}`,
        method: 'delete',
        data: {list: listOfIds}
    })
}

export function downloadDocument(objectId, documentTypeId, documentId) {
    return http.request({
        url: DOCUMENT_URL + `/${documentTypeId}/${objectId}/${documentId}/download`,
        method: 'get',
        responseType: 'blob'
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        const fileExtension = response.type.substr((response.type.lastIndexOf('/') + 1));
        link.setAttribute('download', 'file.' + fileExtension); //or any other extension
        document.body.appendChild(link);
        link.click();
    })
}

export function downloadLocalDocument(documentId) {
    return http.request({
        url: DOCUMENT_URL + `/${documentId}/local-download`,
        method: 'GET',
        responseType: 'blob'
    })
}

export function downloadListOfDocuments(objectId, documentTypeId, list, filename = 'documents') {
    return http.request({
        url: DOCUMENT_URL + `/${documentTypeId}/${objectId}/download`,
        method: 'post',
        data: {list: list},
        responseType: 'blob'
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${filename}.zip`); //or any other extension
        document.body.appendChild(link);
        link.click();
    })
}

export function copyDocuments(objectId, documentTypeId, data) {
    return http.request({
        url: DOCUMENT_URL + `/${documentTypeId}/${objectId}/copy`,
        method: 'post',
        data: data
    })
}

export function createDocument(objectId, documentTypeId, data) {
    return http.request({
        url: DOCUMENT_URL + `/${documentTypeId}/${objectId}`,
        method: 'post',
        data
    })
}


export function downloadGenericExportFile(data, filetype) {
    return http.request({
        url: DOCUMENT_URL + `/generic-export?${filetype}`,
        method: 'post',
        responseType: 'blob',
        data: data
    }).then((response) => {
        let blob = new Blob([response], {type: 'application/'+filetype});
        // if (filetype === 'pdf') {
        //
        //
        // } else {
        //     let blob = new Blob([response], {type: 'application/vnd.ms-excel'});
        //
        // }

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `export.${filetype}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    })
}

export function getOwnerDocuments(documentTypeId) {
    return http.request({
        url: DOCUMENT_URL + `/owner/${documentTypeId}`,
        method: 'get'
    })
}

export function downloadDocumentFromUrl(url) {
    return http.request({
        url: url,
        method: 'GET',
        responseType: 'blob'
    })
}
