<template>
    <div :key="componentFormKey" v-if="loadData">
        <b-form @submit.prevent="updateRatePlan">
            <b-row>
                <b-col sm="6" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.plan_name">
                            <template v-slot:input="data">
                                <label>{{$t('NAME')}}</label>
                                <b-form-input type="text" v-model="object.plan_name" :state="data.state"></b-form-input>
                            </template>
                        </app-input-control>
                </b-col>
                <b-col sm="6" class="mb-4">
                    <app-input-control :error-object="validationErrorObject.rate_plan_type">
                        <template v-slot:input="data">
                            <app-select :disabled="ratePlanTypeDisabled" :label="$t('TYPE')"
                                        v-model="object.rate_plan_type.id"
                                        :options="rate_plan_type_list" :state="data.state"></app-select>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col sm="6" class="mb-4">
                            <app-select disabled :label="$t('LINKED_TO')"
                                        v-model="object.parent"
                                        :options="ratePlanList" ></app-select>
                </b-col>
                <b-col sm="6" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.linked_value">
                            <template v-slot:input="data">
                                <label>{{$t('LINKED_VALUE')}}
                                    <app-access-control  tag="span" :access_control_context="{function:C_UNIT_RATE_PLAN_MASK_SETUP,unit:unit_id,distribution:object.distribution,key:AC_RATE_PLAN_FORM}"
                                                         @AccessControlEvent="addToAccessControlCounter()">
                                        <a v-if="!primaryRateInputDisabled" class="cursor-pointer" @click="toggleSeasonalRateMask">({{$t('SEASONAL')}})</a>
                                    </app-access-control>
                                </label>
                                <b-input-group >
                                    <b-form-input
                                        :disabled="primaryRateInputDisabled || object.mask_setup.length > 1 || yearPeriod"
                                        :state="data.state" v-model="object.linked_value">
                                    </b-form-input>
                                    <b-input-group-append>
                                        <app-select :disabled="primaryRateInputDisabled" v-model="object.linked_type"
                                                    :options="relationTypeOptions"></app-select>
                                    </b-input-group-append>
                                </b-input-group>
                                <app-access-control  tag="span" :access_control_context="{function:C_UNIT_RATE_PLAN_MASK_SETUP,unit:unit_id,distribution:object.distribution,key:AC_RATE_PLAN_FORM}"
                                                     @AccessControlEvent="addToAccessControlCounter()">
                                <period-rate-mask-list
                                    :mask-setup="object.mask_setup"
                                    v-if="seasonalRateMaskVisible === true"
                                    @update="setMaskSetup">
                                </period-rate-mask-list>
                                </app-access-control>
                            </template>
                        </app-input-control>
                </b-col>
                <b-col sm="6" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.distribution">
                            <template v-slot:input="data">
                                <label>{{$t('DISTRIBUTION')}}</label>
                                <app-select :disabled="ratePlanTypeDisabled" v-model="object.distribution"
                                            :options="distribution_list" :state="data.state">
                                </app-select>
                            </template>
                        </app-input-control>
                </b-col>
                <b-col sm="6" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.cancellation_policy">
                            <template v-slot:input="data">
                                <label>{{$t('CANCELLATION_POLICY')}}</label>
                                <app-select :state="data.state" v-model="object.cancellation_policy" :options="cancellationPolicyList"
                                            text-field="label">
                                </app-select>
                            </template>
                        </app-input-control>
                </b-col>

                <app-access-control  sm="6" class="mb-4" :access_control_context="{function:C_UNIT_RATE_PLAN_LOS_V,unit:unit_id,distribution:object.distribution,key:AC_RATE_PLAN_FORM}"
                                    @AccessControlEvent="addToAccessControlCounter()">
                    <app-input-control :error-object="validationErrorObject.los">
                        <template v-slot:input="data">
                            <app-select :state="data.state"  :label="$t('LOS')" v-model="object.los" :options="losListFormated"   :search-empty-item="false"></app-select>
                        </template>
                    </app-input-control>
                </app-access-control>

                <app-access-control sm="6" class="mb-4"   :access_control_context="{function:C_UNIT_RATE_PLAN_COMMUNICATION_TYPE,distribution:object.distribution,unit:unit_id,rate_plan_id:id,key:AC_RATE_PLAN_FORM}"
                                    @AccessControlEvent="addToAccessControlCounter()">
                    <template v-slot:default="props">
                        <label>
                            {{$t('OUTBOUND')}}
                        </label>
                        <app-input-control :error-object="validationErrorObject.cm_communication_type">
                            <template v-slot:input="data">

                                <app-select  :disabled="props.disabled"  :search-empty-item="false" :state="data.state" value-field="value" text-field="name" v-model="object.cm_communication_type" :options="nextpax_type_options">

                                </app-select>
                            </template>
                        </app-input-control>
                    </template>
                </app-access-control>
            </b-row>
            <b-row v-if="object.distribution === BOOKING">
                <b-col lg="6" md="6" class="mb-4">
                    <b-form-checkbox v-model="object.virtual_card">{{$t('VIRTUAL_CARD')}}</b-form-checkbox>
                </b-col>

            </b-row>

            <b-row
                v-if="ratePlan.rate_plan_category === RATE_PLAN_CATEGORY_DISTRIBUTION && (object.distribution !== NEXTPAX || Number(object.cm_communication_type) === CM_RATE_PLAN_COMMUNICATION_TYPE_INBOUND)">
                <b-col md="12" lg="12" class="mb-4">
                    <distribution-reservation-parameter-item :parameter="parameter"
                                                             @update="updateParameter"
                                                             :supported-languages="supportedLanguages">
                    </distribution-reservation-parameter-item>
                </b-col>
            </b-row>

            <app-access-control tag="span" :access_control_context="{function:C_UNIT_RATE_PLAN_RESTRICTIONS,unit:unit_id,type:ratePlan.rate_plan_category,key:AC_RATE_PLAN_FORM}"
                                @AccessControlEvent="addToAccessControlCounter()">
                <form-header>
                    {{$t('INHERIT_FROM_LINKED_RATE_PLAN')}}
                </form-header>
                <b-form-group>
                    <b-checkbox :key="index"  v-for="(restriction, index) in restriction_setup_list"  v-if="restriction.id !== SALES_ENABLED"
                                 switch
                                 v-model="object.inherit_restrictions"
                                 :value="restriction.id"
                    >{{restriction.label}}
                    </b-checkbox>
                </b-form-group>
            </app-access-control>
            <b-row class="mt-4">
                <b-col sm="6">
                    <rate-plan-submit   :loading="saving"></rate-plan-submit>
                </b-col>
                <app-access-control sm="6" :access_control_context="{function:C_UNIT_RATE_PLAN_E,unit: unit_id,rate_plan_category:ratePlan.rate_plan_category, key:AC_RATE_PLAN_FORM}"  @AccessControlEvent="addToAccessControlCounter()">
                    <template v-slot="props">
                        <b-tooltip v-if="props.messages.length"
                                   triggers="hover"
                                   :title="props.messages[0].message"
                                   :target="'rate_plan_delete_'+ratePlan.id">
                        </b-tooltip>
                        <app-button-delete :id="'rate_plan_delete_'+ratePlan.id"  :disabled="primaryRateInputDisabled || props.disabled" :loading="deleteState"
                                            @click="showDeleteDialog=true"
                                            class="pull-right">

                        </app-button-delete>
                    </template>
                </app-access-control>
            </b-row>
            <rate-plan-delete-dialog :show="showDeleteDialog" @confirm="deleteRatePlan"
                                     @cancel="showDeleteDialog=false" :delete_title="true">
            </rate-plan-delete-dialog>

        </b-form>
    </div>
</template>

<script>
    import PeriodRateMaskList from "@/components/pricing/rate_plan/period/PeriodRateMaskList";
    import RatePlanSubmit from '@/components/app/AppButton/AppButtonSubmit'
    import RatePlanDeleteDialog from '@/components/app/form/AppConfirmationDialog'
    import RatePlanDelete from '@/components/app/AppButton/AppButtonDelete'
    import {notifySuccess, toast} from "@/shared/plugins/toastr"
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {
        SYSTEM_STANDARD_RATE,
        SYSTEM_NON_REFUNDABLE_RATE,
        SETUP_PARAMETER_RESERVATION_NOTE_SETUP,
        RATE_PLAN_CATEGORY_DISTRIBUTION,
        CM_RATE_PLAN_COMMUNICATION_TYPE_INBOUND,
        BOOKING,
        EXPEDIA,
        TRIP_ADVISOR,
        NEXTPAX, RATE_PLAN_TYPE_NON_REFUNDABLE, RATE_PLAN_TYPE_STANDARD,
    } from "@/shared/constants";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {
        getPricingRatePlanList,
        fetchPricingRatePlan,
        getPricingLosList, getRatePlanType
    } from "@/services/pricing";
    import {AC_RATE_PLAN_FORM} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {
        C_UNIT_RATE_PLAN_LOS_V,
        C_UNIT_RATE_PLAN_APPLY_MIN_STAY_V,
        C_UNIT_RATE_PLAN_RESTRICTIONS,
        C_UNIT_RATE_PLAN_E,
        C_UNIT_RATE_PLAN_COMMUNICATION_TYPE, C_UNIT_RATE_PLAN_MASK_SETUP
    } from "@/shared/component_permission";
    import {
        ACCOMMODATION_PRICING_RATE_PLAN_NAME_VALIDATION_FAIL,
        ACCOMMODATION_PRICING_RATE_PLAN_DISTRIBUTION_VALIDATION_FAIL,
        ACCOMMODATION_PRICING_RATE_PLAN_TYPE_VALIDATION_FAIL,
        ACCOMMODATION_PRICING_RATE_PLAN_CANCELLATION_POLICY_VALIDATION_FAIL,
        ACCOMMODATION_PRICING_RATE_PLAN_LINKED_VALUE_VALIDATION_FAIL,
        ACCOMMODATION_PRICING_RATE_PLAN_LINKED_TYPE_VALIDATION_FAIL,
        ACCOMMODATION_PRICING_RATE_PLAN_LINKED_VALUE_PERC_VALIDATION_FAIL,
        ACCOMMODATION_PRICING_RATE_PLAN_LOS_VALIDATION_FAILED,
        ACCOMMODATION_PRICING_RATE_PLAN_COMMUNICATION_TYPE,
        ACCOMMODATION_PRICING_RATE_PLAN_COMMUNICATION_TYPE_OUTBOUND_EXIST
    } from "@/shared/error_codes";
    import AppInputControl from "@/components/app/AppInputControl";
    import AppAccessControl from "@/components/app/AppAccessControl";
    import AppButtonDelete from '@/components/app/AppButton/AppButtonDelete';

    import FormHeader from "@/components/app/form/FormHeader";
    import {MIN_STAY, MAX_STAY, RELEASE_PERIOD, CLOSED_ON_ARRIVAL, CLOSED_ON_DEPARTURE, SALES_ENABLED} from "@/shared/constants";

    import {getPropertyCancelationPolicies} from "@/services/property";
    import {getRestrictionList} from "@/services/pricing/restriction";
    import {getDistributionList} from "@/services/distribution";
    import AppButton from "@/components/app/AppButton/AppButton";
    import {getReservationParameter, saveReservationParameter} from "@/services/accommodation_reservation";
    import AppTranslationInput from "@/components/app/translation/AppTranslationInput";
    import {getSystemLanguageList} from "@/services/system";
    import DistributionReservationParameterTypeItem
        from "@/components/channel_manager/distribution/DistributionReservationParameterTypeItem";
    import DistributionReservationParameterItem
        from "@/components/channel_manager/distribution/DistributionReservationParameterItem";
    import moment from "moment";
    import {fetchAccessControlData} from "@/services/access";
    import {cloneDeep} from "lodash";


    export default {
        name: "update-rate-plan-form",
        props: {
            id: {
                type: Number,
            }
        },
        mixins: [getErrorMessage, AccessControlComponent],
        data() {
            return {
                nextpax_type_options:[
                    {value:1, name:this.$t('YES')},
                    {value:2, name:this.$t('NO')},
                ],
                showDeleteDialog: false,
                deleteState: false,
                EXPEDIA,
                TRIP_ADVISOR,
                BOOKING,
                NEXTPAX,
                SYSTEM_NON_REFUNDABLE_RATE,
                SYSTEM_STANDARD_RATE,
                object: {
                    plan_name: null,
                    distribution: null,
                    linked_type: null,
                    linked_value: null,
                    cancellation_policy: null,
                    parent: null,
                    los: null,
                    rate_plan_type: null,
                    inherit_restrictions:[],
                    virtual_card:false,
                    cm_communication_type:null,
                    mask_setup: []
                },
                rate_plan_type_list: [
                    /*{id: 1, name: 'Standard'},*/
                ],
                ratePlanList: [],
                relationTypeOptions: [
                    {id: 'perc', name: '%'},
                    {id: 'fix', name: 'fix'}
                ],
                error_code_list: [],
                error_message_list: [],
                C_UNIT_RATE_PLAN_LOS_V,
                C_UNIT_RATE_PLAN_RESTRICTIONS,
                C_UNIT_RATE_PLAN_APPLY_MIN_STAY_V,
                C_UNIT_RATE_PLAN_COMMUNICATION_TYPE,
                AC_RATE_PLAN_FORM,
                C_UNIT_RATE_PLAN_MASK_SETUP,
                access_control_fetch_key: AC_RATE_PLAN_FORM,
                access_control_components: 6,
                validationErrorObject: {
                    plan_name: ACCOMMODATION_PRICING_RATE_PLAN_NAME_VALIDATION_FAIL,
                    rate_plan_type: ACCOMMODATION_PRICING_RATE_PLAN_TYPE_VALIDATION_FAIL,
                    distribution: ACCOMMODATION_PRICING_RATE_PLAN_DISTRIBUTION_VALIDATION_FAIL,
                    cancellation_policy:ACCOMMODATION_PRICING_RATE_PLAN_CANCELLATION_POLICY_VALIDATION_FAIL,
                    linked_value: [ACCOMMODATION_PRICING_RATE_PLAN_LINKED_VALUE_PERC_VALIDATION_FAIL, ACCOMMODATION_PRICING_RATE_PLAN_LINKED_VALUE_VALIDATION_FAIL,ACCOMMODATION_PRICING_RATE_PLAN_LINKED_TYPE_VALIDATION_FAIL],
                    los:[ACCOMMODATION_PRICING_RATE_PLAN_LOS_VALIDATION_FAILED],
                    cm_communication_type:[ACCOMMODATION_PRICING_RATE_PLAN_COMMUNICATION_TYPE,ACCOMMODATION_PRICING_RATE_PLAN_COMMUNICATION_TYPE_OUTBOUND_EXIST]
                },
                saving: false,
                componentFormKey:1,
                SALES_ENABLED,
                show:false,
                C_UNIT_RATE_PLAN_E,
                cancellationPolicyList:[],
                restriction_setup_list:[],
                ratePlan:null,
                losList:[],
                unit_id:null,
                loadData:false,
                distribution_list:[],
                parameter: null,
                supportedLanguages: [],
                RATE_PLAN_CATEGORY_DISTRIBUTION,
                CM_RATE_PLAN_COMMUNICATION_TYPE_INBOUND,
                ratePlanTypeList: [],
                seasonalRateMaskVisible: false,
                seasonalRateMaskChanged: false
            }
        },

        computed: {
            firstDayCurrentYear() {
                return moment().startOf('year').format('MM/DD')
            },
            endDayCurrentYear() {
                return moment().endOf('year').format('MM/DD')
            },
            yearPeriod(){
                if (
                    this.object.mask_setup.length === 1 &&
                    (((this.object.mask_setup[0].period_start > this.firstDayCurrentYear) &&
                    (this.object.mask_setup[0].period_end < this.endDayCurrentYear)) ||
                    ((this.object.mask_setup[0].period_start === this.firstDayCurrentYear) &&
                        (this.object.mask_setup[0].period_end < this.endDayCurrentYear)) ||
                    ((this.object.mask_setup[0].period_start > this.firstDayCurrentYear) &&
                        (this.object.mask_setup[0].period_end === this.endDayCurrentYear)))
                ){
                    return true
                }
                return false
            },
            primaryRateInputDisabled() {
                if (this.ratePlan) {
                    if (this.ratePlan.rate_plan_category === 1) {
                        return true;
                    }
                }
                return false
            },
            ratePlanTypeDisabled() {

                if (this.object.rate_plan_category === SYSTEM_NON_REFUNDABLE_RATE || this.object.rate_plan_category === SYSTEM_STANDARD_RATE) {
                    return true
                } else {
                    return false
                }
            },
            losListFormated() {
                let losList = []
                if (this.losList.length > 0) {
                    for (let los of this.losList) {
                        if (los.min_stay === this.ratePlan.los.min_stay && los.max_stay === this.ratePlan.los.max_stay) {
                            this.object.los = los.id
                        }
                        let object = {
                            id: los.id,
                            name: los.min_stay + (!los.max_stay ? ' + ' : ' - ' + los.max_stay)
                        }
                        losList.push(object)
                    }
                }
                return losList
            },
            company() {
                return this.$store.getters['user/getCompany']
            }
        },
        components: {
            DistributionReservationParameterItem,
            DistributionReservationParameterTypeItem,
            AppTranslationInput,
            AppButton,
            FormHeader,
            AppButtonDelete,

            AppSelect,
            RatePlanSubmit,
            RatePlanDeleteDialog,
            RatePlanDelete,
            AppInputControl,
            AppAccessControl,
            PeriodRateMaskList
        },
        methods: {
            toggleSeasonalRateMask() {
                this.seasonalRateMaskVisible = !this.seasonalRateMaskVisible
            },
            setMaskSetup(maskSetup) {
                this.$set(this.object, 'mask_setup', maskSetup)
                this.seasonalRateMaskChanged = true
                if (maskSetup.length !== 1) {
                    this.$set(this.object, 'linked_value', null)
                } else {
                    const maskSetupPeriod = maskSetup.find(el => true)
                    if (maskSetupPeriod && maskSetupPeriod.period_start === this.firstDayCurrentYear && maskSetupPeriod.period_end  === this.endDayCurrentYear) {
                        this.$set(this.object, 'linked_value', maskSetupPeriod.value)
                    }
                }
            },
            searchDistribution() {
                getDistributionList({company: this.company, for_reservation:1, sorted:1}).then(res => {
                    this.distribution_list = res.data
                })
            },

            prepareRequest() {
                const object = cloneDeep(this.object)
                const  data = {
                    plan_name: object.plan_name,
                    distribution: object.distribution,
                    parent_rate_plan: object.parent,
                    cancellation_policy: object.cancellation_policy,
                    linked_type: object.linked_type,
                    linked_value: object.linked_value,
                    los: object.los,
                    rate_plan_type: object.rate_plan_type ? object.rate_plan_type.id : null,
                    unit: object.unit,
                    rate_plan_category: object.rate_plan_category
                }
                if(object.hasOwnProperty('virtual_card') && this.object.distribution === BOOKING){
                    data.virtual_card = object.virtual_card
                }
                if(object.hasOwnProperty('cm_communication_type') && this.object.distribution === NEXTPAX){
                    data.cm_communication_type = object.cm_communication_type
                }
                if(this.seasonalRateMaskChanged){
                    if(object.hasOwnProperty('mask_setup') && (object.mask_setup.length > 1 || object.mask_setup.length === 0))  {
                        data.linked_value = object.linked_value
                    }
                    data.mask_setup = this.prepareMaskSetup(object)
                } else if(this.yearPeriod || (object.hasOwnProperty('mask_setup') && object.mask_setup.length > 1)){
                    data.linked_value = null
                    data.mask_setup = this.prepareMaskSetup(object)
                }
                return {...data,...this.prepareRestrictionRequest(object)}
            },
            prepareMaskSetup(requestObject){
                let maskSetup = []
                if (requestObject.hasOwnProperty('mask_setup') && Array.isArray(requestObject.mask_setup) && requestObject.mask_setup.length > 0) {
                    requestObject.mask_setup.forEach(maskSetupPeriod => {
                        maskSetup.push({
                            period_start:maskSetupPeriod.period_start,
                            period_end: maskSetupPeriod.period_end,
                            value_type_id: this.object.linked_type === 'fix' ? 2 : 1,
                            value: maskSetupPeriod.period_start === this.firstDayCurrentYear && maskSetupPeriod.period_end  === this.endDayCurrentYear ? this.object.linked_value :  maskSetupPeriod.value
                        })
                    })
                }
                else {
                    maskSetup.push({
                        period_start:this.firstDayCurrentYear,
                        period_end: this.endDayCurrentYear,
                        value_type_id: this.object.linked_type === 'fix' ? 2 : 1,
                        value: this.object.linked_value
                    })
                }
                return maskSetup
            },
            prepareRestrictionRequest(requestObject){
                let restriction = {
                    inherit_minimum_stay:false,
                    inherit_maximum_stay:false,
                    inherit_release_period:false,
                    inherit_check_in_days:false,
                    inherit_check_out_days:false
                }
                if(requestObject.hasOwnProperty('inherit_restrictions') && requestObject.inherit_restrictions.length > 0){
                    requestObject.inherit_restrictions.forEach(restriction_id =>{
                        if(restriction_id === MIN_STAY){
                            restriction.inherit_minimum_stay = true
                        }
                        if(restriction_id === MAX_STAY){
                            restriction.inherit_maximum_stay = true
                        }
                        if(restriction_id === RELEASE_PERIOD){
                            restriction.inherit_release_period = true
                        }
                        if(restriction_id === CLOSED_ON_ARRIVAL){
                            restriction.inherit_check_in_days = true
                        }
                        if (restriction_id === CLOSED_ON_DEPARTURE){
                            restriction.inherit_check_out_days = true
                        }
                    })
                }
                return restriction
            },
            updateRatePlan() {
                let object = this.prepareRequest()
                this.saving = true
                this.$store.dispatch('pricing/actionUpdatePricingRatePlan', {id: this.id, object})
                    .then(() => {
                            toast({
                                'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                                'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                                'type': 'success',
                                'timeout': 2000
                            })
                            if(
                                this.ratePlan.rate_plan_category === RATE_PLAN_CATEGORY_DISTRIBUTION &&
                                (object.distribution !== NEXTPAX || Number(object.cm_communication_type) === CM_RATE_PLAN_COMMUNICATION_TYPE_INBOUND)
                            ) {
                                this.saveParameter()
                            } else {
                                this.$emit('ratePlanSaved', this.id)
                            }
                        }, error => {
                            this.showErrorMessages(error, this.validationErrorObject)
                        }
                    ).finally(() => {
                    this.saving = false
                })
            },
            saveParameter(){
                let formattedParameter = {}
                if (this.parameter.complex_object && typeof this.parameter.complex_object === 'object' && this.parameter.complex_object.hasOwnProperty('type')) {
                    if (this.parameter.complex_object.type === 'custom') {
                        let parameterValue = {}
                        for (const [key, val] of Object.entries(this.parameter.complex_object.objects)) {
                            if (val && val.value !== null && val.value !== "") {
                                parameterValue[key] = val.value
                            } else {
                                parameterValue[key] = val ? (val.value ? val.value : null) : null
                            }
                        }
                        formattedParameter = ({...this.parameter, ...{value_text: JSON.stringify(parameterValue), complex_object: null}})
                    } else if (this.parameter.complex_object.type === 'composite') {
                        let parameterValue = []
                        for (const parameterObject of this.parameter.complex_object.objects) {
                            let parameterItemValue = {}
                            for (const [key, val] of Object.entries(parameterObject)) {
                                if (key === 'content') {
                                    parameterItemValue[key] = {}
                                    parameterItemValue[key].lang_id = val ? val.lang_id ? val.lang_id : null : null
                                    parameterItemValue[key].value = val ? val.value ? val.value : null : null
                                } else {
                                    if (val && val.value !== null && val.value !== "") {
                                        parameterItemValue[key] = val.value
                                    } else {
                                        parameterItemValue[key] = val ? (val.value ? val.value : null) : null
                                    }
                                }
                            }
                            parameterValue.push(parameterItemValue)
                        }
                        formattedParameter = ({...this.parameter, ...{value_text: JSON.stringify(parameterValue), complex_object: null}})
                    } else {
                        formattedParameter = ({...this.parameter, ...{complex_object: null}})
                    }
                } else {
                    formattedParameter = ({...this.parameter, ...{complex_object: null}})
                }
                saveReservationParameter({item: formattedParameter}).then(() => {
                    notifySuccess()
                    this.$emit('ratePlanSaved', this.id)
                }).catch(error => {
                    this.showErrorMessages(error)
                }).finally(() => {
                        this.saving = false
                    }
                )
            },
            deleteRatePlan() {
                this.$store.dispatch('pricing/actionDeletePricingRatePlan', {
                    id: this.ratePlan.id,
                    unit: this.$route.params.unit_id
                })
                    .then(() => {
                            toast({
                                'title': this.$t('NOTIFICATIONS.DELETE_SUCCESS.TITLE'),
                                'message': this.$t('NOTIFICATIONS.DELETE_SUCCESS.MESSAGE'),
                                'type': 'success',
                                'timeout': 2000
                            })

                            this.$emit('ratePlanSaved')
                        if (this.ratePlan.rate_plan_category === SYSTEM_NON_REFUNDABLE_RATE) {
                            this.showDeleteDialog = false
                            this.$store.commit('setSystemNonRef', 0)
                            this.$emit('deleteNrf')
                        }
                            this.$emit('ratePlanSaved', this.id)

                        }, error => {
                            toast({
                                'title': "Action  fail",
                                'message': this.getMessages(error),
                                'type': 'error',
                                'timeout': 2000
                            })
                        }
                    ).finally(() => this.showDeleteDialog = false)
            },
            cleanObject(object) {
                Object.keys(object).forEach((key) => (object[key] == null) && delete object[key] && (object[key] ==  []))
                return object
            },
            getRateList() {
                getPricingRatePlanList({unit: this.unit_id, no_distribution:1, limit: 1000})
                    .then(response => {
                        for (let item of response.data.items) {
                            if (item.id !== this.rate.id) {
                                this.ratePlanList.push(item)
                            }
                        }
                    }).finally(()=>{
                        this.show = true
                })
            },
            setValues() {
                this.object.plan_name = this.ratePlan.name
                this.object.parent = this.ratePlan.parent.id
                this.object.distribution = this.ratePlan.distribution.id
                this.object.cancellation_policy = this.ratePlan.cancellation_policy.id
                this.object.linked_type = this.ratePlan.linked_type
                this.object.linked_value = this.ratePlan.linked_value
                this.object.apply_min_stay = this.ratePlan.apply_min_stay
                this.object.rate_plan_type = this.ratePlan.rate_plan_type
                this.object.type = this.ratePlan.type
                this.object.unit = this.ratePlan.unit.id
                this.object.rate_plan_category = this.ratePlan.rate_plan_category
                this.object.inherit_restrictions = this.setRestrictionMaps(this.ratePlan)
                this.object.virtual_card = this.ratePlan.virtual_card === 1 ? true : false
                this.object.cm_communication_type = this.ratePlan.cm_communication_type
                this.unit_id = this.ratePlan.unit.id
                this.object.mask_setup = this.ratePlan.mask_setup
                this.seasonalRateMaskChanged = false
            },
            setRestrictionMaps(ratePlan){
                let list = []
                if(ratePlan.hasOwnProperty('inherit_minimum_stay') && ratePlan.inherit_minimum_stay){
                    list.push(MIN_STAY)
                }
                if(ratePlan.hasOwnProperty('inherit_maximum_stay') && ratePlan.inherit_maximum_stay){
                    list.push(MAX_STAY)
                }
                if(ratePlan.hasOwnProperty('inherit_release_period') && ratePlan.inherit_release_period){
                    list.push(RELEASE_PERIOD)
                }
                if(ratePlan.hasOwnProperty('inherit_check_in_days') && ratePlan.inherit_check_in_days){
                    list.push(CLOSED_ON_ARRIVAL)
                }
                if(ratePlan.hasOwnProperty('inherit_check_out_days') && ratePlan.inherit_check_out_days){
                    list.push(CLOSED_ON_DEPARTURE)
                }
                return list
            },
            getReservationsParameter() {
                let req = {
                    rate_plan: this.id,
                    context_company:this.context_company
                }

                getReservationParameter(SETUP_PARAMETER_RESERVATION_NOTE_SETUP, req).then(response => {
                    this.parameter = response.data
                })
            },
            getSupportedLanguages() {
                getSystemLanguageList().then(response => {
                    response.data.forEach(el => {
                        this.supportedLanguages.push(el.name)
                    })
                })
            },
            forceReRender(){
                this.componentFormKey +=1
            },
            updateParameter(setupParameter) {
                this.parameter = setupParameter
            },
        },
        watch: {
            ratePlanList:{
                handler(list){
                    if(list.length > 0){
                        let nrfRate = list.find(el => el.rate_plan_category === SYSTEM_NON_REFUNDABLE_RATE)
                        const nonRefundableTypeIncludedInList = this.rate_plan_type_list.find(el => el.id === RATE_PLAN_TYPE_NON_REFUNDABLE)
                        if ((nrfRate || this.ratePlan.rate_plan_category === SYSTEM_NON_REFUNDABLE_RATE) && !nonRefundableTypeIncludedInList) {
                            const nonRefundableRatePlanTypeIndex = this.ratePlanTypeList.findIndex(ratePlanType => {
                                return ratePlanType.id === RATE_PLAN_TYPE_NON_REFUNDABLE
                            })
                            if (nonRefundableRatePlanTypeIndex > -1) {
                                this.rate_plan_type_list.push({
                                    id: this.ratePlanTypeList[nonRefundableRatePlanTypeIndex].id,
                                    name: this.ratePlanTypeList[nonRefundableRatePlanTypeIndex].label,
                                })
                            }
                        } else if (nonRefundableTypeIncludedInList && !(nrfRate || this.ratePlan.rate_plan_category === SYSTEM_NON_REFUNDABLE_RATE)) {
                            const index = this.rate_plan_type_list.findIndex(ratePlanType => {
                                return ratePlanType.id === RATE_PLAN_TYPE_NON_REFUNDABLE
                            })
                            this.rate_plan_type_list.splice(index, 1)
                        }
                    }
                }, immediate:true
            },
            'object.rate_plan_type.id':{
                handler(value){
                    if(value && this.ratePlanList.length){
                        let parenRatePlan =  this.ratePlanList.find(el => el.rate_plan_category === value)
                        if(parenRatePlan){
                            this.object.parent = parenRatePlan.id
                        } else {
                            this.object.parent = null
                        }
                    } else if (!value)  {
                        this.object.parent = null
                    }
                }
            },
            'object.mask_setup':{
                handler(value){
                    if(value && (this.object.mask_setup.length > 1 || this.yearPeriod)){
                        this.seasonalRateMaskVisible = true
                    }
                }
            },
            id:{
                handler(value){
                    this.loadData = false
                    fetchPricingRatePlan(value).then(response =>{
                        this.ratePlan =  response.data
                        if(!response.data){
                            return
                        }

                        this.setValues()
                        getPropertyCancelationPolicies(response.data.property.id).then(response =>{
                            this.cancellationPolicyList = response.data
                        })
                        getPricingLosList({unit: response.data.unit.id}).then(response =>{
                            this.losList = response.data
                        })
                        getPricingRatePlanList({unit: response.data.unit.id, limit: 1000})
                            .then(response => {
                                for (let item of response.data.items) {
                                    if (item.id !== this.ratePlan.id) {
                                        this.ratePlanList.push(item)
                                    }
                                }
                            })
                    }).finally(()=>{
                        this.loadData = true
                    })
                    this.getReservationsParameter()
                }, immediate:true
            },
            'object.distribution':{
                handler(value){

  /*                  if(value === NEXTPAX){
                        let request = {
                            data: []
                        }
                        request.data.push({
                            uid: C_UNIT_RATE_PLAN_COMMUNICATION_TYPE,
                            function: C_UNIT_RATE_PLAN_COMMUNICATION_TYPE,
                            distribution:value,
                            unit:this.unit_id,
                        })
                        fetchAccessControlData(AC_RATE_PLAN_FORM_NEXTPAX, request).then(response => {
                            this.object.cm_communication_type =   this.object.cm_communication_type === null && response.data[C_UNIT_RATE_PLAN_COMMUNICATION_TYPE].disabled ? 2 : null
                        })
                    }*/
                    let maskRequest = {
                        data: []
                    }
                    maskRequest.data.push({
                        uid: C_UNIT_RATE_PLAN_MASK_SETUP,
                        function: C_UNIT_RATE_PLAN_MASK_SETUP,
                        distribution: value,
                        unit: this.unit_id,
                    })
                    fetchAccessControlData(AC_RATE_PLAN_FORM, maskRequest).then(response => {
                        if (response.data[C_UNIT_RATE_PLAN_MASK_SETUP].disabled === true) {
                            this.object.mask_setup = []
                        }
                    })
                    this.object.distribution = value
                    this.forceReRender()

                }
            }
        },
        created() {
            this.searchDistribution()
            this.getSupportedLanguages()
            getRestrictionList()
                .then(response => {
                    this.restriction_setup_list = response.data
                })

            getRatePlanType().then((response) => {
                this.ratePlanTypeList = response.data
                const standardRatePlanTypeIndex = this.ratePlanTypeList.findIndex(ratePlanType => {
                    return ratePlanType.id === RATE_PLAN_TYPE_STANDARD
                })
                if (standardRatePlanTypeIndex > -1) {
                    this.rate_plan_type_list.push({
                        id: this.ratePlanTypeList[standardRatePlanTypeIndex].id,
                        name: this.ratePlanTypeList[standardRatePlanTypeIndex].label,
                    })
                }
            })
        }
    }
</script>

<style scoped>

</style>
