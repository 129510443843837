<template>
    <form @submit.prevent="search" @reset.prevent="resetForm" class="filter">
        <b-row>
            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t('NUMBER')}} / {{$t('DESCRIPTION')}}</label>
                <b-input v-model="filter.invoice_number_description"></b-input>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t('TYPE')}}</label>
                <app-select mode="multiselect"
                            :options="invoiceSubtypeList"
                            v-model="filter.invoice_subtype"></app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t('RECIPIENT')}}</label>
                <app-select mode="search"
                            :search="searchPartner"
                            v-model="partner">
                    <template v-slot:text="data">
                        {{data.item.name}} {{data.item.last_name}}
                    </template>
                </app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t("_DATE")}}</label>
                <app-select :options="dateOptions" v-model="dateOptionValue"></app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t("FROM")}}</label>
                <app-date-picker :disabled="!dateOptionValue" v-model="fromDate" :max-date="toDate"></app-date-picker>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t("TO")}}</label>
                <app-date-picker :disabled="!dateOptionValue" v-model="toDate" :min-date="fromDate" :from-date="fromDate"></app-date-picker>
            </b-col>
        </b-row>

        <b-row class="align-items-end">
            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t('STATUS')}}</label>
                <app-select mode="multiselect"
                            :options="statusList"
                            v-model="filter.status" text-field="label"></app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t("AMOUNT")}} {{$t("FROM").toLowerCase()}}</label>
                <app-number-input v-model="filter.total_from" allow-decimal></app-number-input>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t("AMOUNT")}} {{$t("TO").toLowerCase()}}</label>
                <app-number-input v-model="filter.total_to" allow-decimal></app-number-input>
            </b-col>

            <b-col xl="3" lg="5" md="6" class="mb-3">
                <app-button-submit :inline="true" :disabled="loading" :loading="loaderSubmit" variant="primary" button_type="search">
                    {{$t("SEARCH") }}
                </app-button-submit>
                <app-button-reset :disabled="loading" :loading="loaderReset" :inline="true"  class="pl-2 ml-2"  ></app-button-reset>
            </b-col>
        </b-row>
    </form>
</template>

<script>
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {cloneDeep} from "lodash";
    import {getInvoiceStatusList} from "@/services/status";
    import {getInvoiceTypeList, getSystemInvoiceTypeList} from "@/services/finance/setup";
    import {EventBus} from "@/shared/EventBus";
    import AppDatePicker from "@/components/app/datetime/AppDatePicker";
    import {getPartnerList} from "@/services/finance/partner";
    import AppButtonReset from "@/components/app/AppButton/AppButtonReset";
    import AppNumberInput from "@/components/app/form/AppNumberInput";

    export default {
        // Invoice Filter
        name: "InvoiceFilter",
        components: {AppNumberInput, AppButtonReset, AppDatePicker, AppSelect, AppButtonSubmit},
        data() {
            return {
                filter: {
                    status: [],
                    invoice_subtype: [],
                    payment_message: null,
                },
                status: {},
                partner: {},
                initialFilter: {},
                currencyList: [],
                loading: false,
                getSystemInvoiceTypeList,
                dateOptions: [
                    {id: 'date_created', name: this.$t('DATE_CREATED')},
                    {id: 'delivery_date', name: this.$t('DELIVERY_DATE')},
                    {id: 'due_date', name: this.$t('DUE_DATE')}
                ],
                dateOptionValue: null,
                fromDate: null,
                toDate: null,
                dateObject: {},
                statusList: [],
                invoiceSubtypeList: [],
                reset:0,
                loaderReset:false,
                loaderSubmit:false
            }
        },
        computed: {
            company() {
                return this.$store.getters['user/getCurrentCompany']
            }
        },
        watch: {
            status(value) {
                if (value) {
                    this.filter.status = value.id
                }
            },
            invoiceSubtype(value) {
                if (value) {
                    this.filter.invoice_subtype = value.id
                }
            },
            partner(value) {
                if (value) {
                    this.filter.partner = value.id
                }
            },
            fromDate(value) {
                if (this.dateOptionValue) {
                    this.dateObject[this.dateOptionValue + '_from'] = value
                }
            },
            toDate(value) {
                if (this.dateOptionValue) {
                    this.dateObject[this.dateOptionValue + '_to'] = value
                }
            },
            dateOptionValue(value, oldValue) {
                if (oldValue !== undefined) {
                    this.fromDate = null
                    this.toDate = null
                    this.dateObject = {}
                }
            },
            '$route.query': {
                handler(queryParams) {
                    const queryParamKeys = Object.keys(queryParams)
                    // if filter contains provided query parameters perform a search with them
                    if (queryParamKeys.length > 0) {
                        for (const [key, value] of Object.entries(queryParams)) {
                            if (Object.keys(this.filter).includes(key)) {
                                if (Array.isArray(this.filter[key])) {
                                    this.$set(this.filter, key, this.filter[key].concat(value))
                                } else {
                                    this.filter[key] = value
                                }
                            }
                        }

                        if (queryParamKeys.includes('total_from')) {
                            this.filter['total_from'] = queryParams.total_from
                        }

                        if (queryParamKeys.includes('partner_type')) {
                            this.filter['partner_type'] = queryParams.partner_type
                        }

                        if (queryParamKeys.includes('invoice_type')) {
                            this.filter['invoice_type'] = queryParams.invoice_type
                        }

                        if (queryParamKeys.includes('date')) {
                            this.dateOptionValue = queryParams.date
                            this.$nextTick(() => {
                                this.fromDate = queryParams.from
                                this.toDate = queryParams.to
                            })
                        }

                        setTimeout(() => {
                            //wait for table component to start listening for event
                            this.search()
                        }, 200)
                    }
                },
                immediate: true
            },
        },
        methods: {
            search() {
                this.reset = 0
                EventBus.$emit('invoiceSearch', {...this.filter, ...this.dateObject, company: this.company.id})
            },
            resetForm() {
                this.reset =1
                if (Object.keys(this.$route.query).length > 0) {
                    this.$router.replace({query: {}})
                }
                this.dateObject = this.partner = this.invoiceSubtype = {}
                this.dateOptionValue = null
                this.filter = cloneDeep(this.initialFilter)
                this.filter.payment_message = null
                EventBus.$emit('invoiceSearch', {...this.filter,  company: this.company.id})
            },
            searchPartner(value) {
                return getPartnerList({
                    search: value,
                    company: this.company.id,
                    results: 20
                })
            },
            searchInvoiceType(value) {
                return getInvoiceTypeList({
                    name: value,
                    company: this.company.id
                })
            }
        },
        created() {
            this.initialFilter = cloneDeep(this.filter)
            EventBus.$on('invoiceLoading', (value) => {
                if(this.reset){
                    this.loaderReset = value
                } else {
                    this.loaderSubmit = value
                }
                this.loading = value
            })
            getInvoiceStatusList().then(response => {
                this.statusList = response.data
            })
            this.searchInvoiceType().then(response => {
                this.invoiceSubtypeList = response.data
            })

        },
        beforeDestroy() {
            EventBus.$off('invoiceLoading')
        }
    }
</script>

<style scoped>

</style>
