<template>
    <div>
        <div class="availability_calendar mb-5" id="calendar_wrapper">
            <div v-if="property_count > 0 && property_unit_count > 0 && root_unit_count > 0" class="sticky_date">
                <b-form class="filter pb-0" :class="totalRows  > 10 ? 'mb-0' : 'mb-4'" @submit.prevent="search">
                    <div class="d-flex justify-content-between" v-if="display_work_mode === MULTI_UNIT_VIEW">
                        <b-row class="d-flex justify-content-start" style="width: 65rem">
                            <template v-if="cities_count > 1">
                                <b-col lg="2" class="mb-3 display-multi-unit" v-if="preloadCityList">
                                    <label>{{ $t("CITY") }}</label>
                                    <app-select mode="multiselect" :options="cityList" v-model="city"></app-select>
                                </b-col>
                                <b-col v-else lg="2" class="mb-3 display-multi-unit">
                                    <label>{{ $t("CITY") }}</label>
                                    <app-select mode="search" :search="searchCity" v-model="city"
                                                :search-clear="true"></app-select>
                                </b-col>
                            </template>
                            <b-col lg="2" class="mb-3 display-multi-unit" v-if="property_unit_count > 1">
                                <label>{{ $t('PROPERTY') }}</label>

                                <app-select
                                    v-if="preloadPropertyList || (propertyList.length > 0 && propertyList.length <= DEFAULT_PAGINATION_LIMIT)"
                                    mode="multiselect" :options="propertyList" set-first
                                    v-model="property"></app-select>
                                <app-select v-else mode="search" :search="searchProperty"
                                            v-model="property"></app-select>

                            </b-col>
                            <b-col md="6" lg="2" class="w-30 display-multi-unit"
                                   v-if="destinationTagOptions.length > 0">
                                <label>{{ $t('DESTINATION_TAG') }}</label>
                                <app-select value-field="id" text-field="label" v-model="destination_tags"
                                            :options="destinationTagOptions" mode="multiselect"></app-select>
                            </b-col>
                            <b-col v-if="customTagOptions.length > 0" md="6" lg="2" class="w-30 display-multi-unit">
                                <label>{{ $t('CUSTOM_TAG') }}</label>
                                <app-select value-field="id" text-field="label" v-model="custom_tags"
                                            :options="customTagOptions" mode="multiselect"></app-select>
                            </b-col>

                            <app-access-control md="6" lg="2" class="w-30 display-multi-unit"
                                                @AccessControlEvent="addToAccessControlCounter()"
                                                :access_control_context="{function:MAIN_CALENDAR_FILTER_GROUP, key: AC_MAIN_CALENDAR}">
                                <label>{{ $t("GROUP") }}</label>
                                <app-select mode="search" text-field="title" value-field="id" :search="searchFilter"
                                            v-model="group">

                                </app-select>
                            </app-access-control>


                            <b-col sm="12" lg="6" class="mb-4"
                                   v-if="cities_count > 1 || property_unit_count > 1 || destination_tags.length > 0 || custom_tags.length > 0">
                                <app-button-submit class="filter-submit-wrapper" :inline="true" :loading="loading"
                                                   variant="primary"
                                                   button_type="search">{{ $t("SEARCH") }}
                                </app-button-submit>
                                <app-button-reset :loading="loading_reset" @click="resetForm"
                                                  class="filter-submit-wrapper ml-3" :inline="true"></app-button-reset>
                            </b-col>
                        </b-row>
                        <div class="d-flex justify-content-end">
                            <b-row>
                                <b-col cols="12" class="d-flex justify-content-end">

                                    <b-form-group class="mr-3 display-settings">
                                        <label>{{ $t('DISPLAY') }}</label>
                                        <app-select :search-empty-item="false" v-model="display" value-field="value"
                                                    text-field="text" set-first :options="display_options"></app-select>
                                    </b-form-group>
                                    <i class="fa fa-chevron-left availability_calendar--load-more "
                                       @click="loadMoreBack"
                                       aria-hidden="true"></i>

                                    <b-form-group class="mr-2 ml-2">
                                        <label>{{ $t('MONTH') }}</label>
                                        <app-month-picker :value="selectMonth"
                                                          @input="updateSelectMonth"></app-month-picker>
                                    </b-form-group>
                                    <i class="fa fa-chevron-right availability_calendar--load-more mr-3"
                                       aria-hidden="true"
                                       @click="loadMore">
                                    </i>

                                    <b-form-group class="width-period">
                                        <label>{{ $t('PERIOD') }}</label>
                                        <app-date-range-picker :max-date-diff="90" :value="dateRange"
                                                               @input="updateRange">
                                        </app-date-range-picker>
                                    </b-form-group>

                                </b-col>
                            </b-row>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between" v-else-if="display_work_mode === MONTHLY_VIEW">
                        <b-row class="d-flex justify-content-start  " style="width: 65rem">
                            <b-col sm="12" lg="3" class="mb-3 display" v-if="property_unit_count > 1">
                                <label>{{ $t('PROPERTY') }}</label>
                                <app-select
                                    v-if="propertyList.length > 0 && propertyList.length <= DEFAULT_PAGINATION_LIMIT"
                                    mode="multiselect" :options="propertyList" set-first
                                    v-model="property"></app-select>
                                <app-select v-else mode="search" :search="searchProperty"
                                            v-model="property"></app-select>
                            </b-col>

                            <b-col sm="12" lg="3" class="mb-3 display" v-if="root_unit_count > 1">
                                <label>{{ $t('UNIT') }}</label>
                                <app-select
                                    mode="multiselect"
                                    :disabled="unitMultiselectDisabled"
                                    :options="unitList" set-first
                                    :search-empty-item="false"
                                    v-model="selectedUnits">
                                </app-select>
                            </b-col>

                            <b-col sm="12" lg="6" class="mb-4"
                                   v-if="property_unit_count > 1 || root_unit_count > 1">
                                <app-button-submit class="filter-submit-wrapper" :inline="true" :loading="loading"
                                                   variant="primary"
                                                   button_type="search">{{ $t("SEARCH") }}
                                </app-button-submit>
                                <app-button-reset :loading="loading_reset" @click="resetForm"
                                                  class="filter-submit-wrapper ml-3" :inline="true"></app-button-reset>
                            </b-col>
                        </b-row>

                        <div class="d-flex justify-content-end">
                            <b-row>
                                <b-col cols="12" class="d-flex justify-content-end">

                                    <b-form-group class="mr-3 display-settings">
                                        <label>{{ $t('DISPLAY') }}</label>
                                        <app-select :search-empty-item="false" v-model="display" value-field="value"
                                                    text-field="text" set-first :options="display_options"></app-select>
                                    </b-form-group>
                                    <i class="fa fa-chevron-left availability_calendar--load-more "
                                       @click="loadMoreBack"
                                       aria-hidden="true"></i>

                                    <b-form-group class="mr-2 ml-2">
                                        <label>{{ $t('MONTH') }}</label>
                                        <app-month-picker
                                            :value="selectMonth"
                                            @input="updateSelectMonth"></app-month-picker>
                                    </b-form-group>
                                    <i class="fa fa-chevron-right availability_calendar--load-more mr-3"
                                       aria-hidden="true"
                                       @click="loadMore">
                                    </i>

                                    <b-form-group class="mr-3 display-settings">
                                        <label>{{ $t('PERIOD') }}</label>
                                        <app-select :search-empty-item="false" v-model="display_month"
                                                    value-field="value"
                                                    text-field="text" set-first :options="month_options"
                                                    @input="updateSelectMonthPeriod"></app-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between" v-else-if="display_work_mode === SINGLE_UNIT">
                        <b-row class="d-flex justify-content-start" style="width: 65rem">
                            <b-col sm="12" lg="3" class="mb-3 display" v-if="property_unit_count > 1">
                                <label>{{ $t('PROPERTY') }}</label>
                                <app-select
                                    v-if="propertyList.length > 0 && propertyList.length <= DEFAULT_PAGINATION_LIMIT"
                                    :options="propertyList" set-first
                                    :search-empty-item="false"
                                    v-model="property"></app-select>
                                <app-select v-else mode="search" :search="searchProperty"
                                            v-model="property"></app-select>
                            </b-col>

                            <b-col sm="12" lg="3" class="mb-3 display" v-if="root_unit_count > 1">
                                <label>{{ $t('UNIT') }}</label>
                                <app-select
                                    :disabled="unitMultiselectDisabled"
                                    :options="unitList" set-first
                                    :search-empty-item="false"
                                    :value="selectedUnits.length === 1 ? selectedUnits[0] : null"
                                    @input="updateSelectedUnit">
                                </app-select>
                            </b-col>

                            <b-col sm="12" lg="6" class="mb-4" v-if="property_unit_count > 1 || root_unit_count > 1">
                                <app-button-submit class="filter-submit-wrapper" :inline="true" :loading="loading"
                                                   variant="primary"
                                                   button_type="search"
                                                   :disabled="!selectedUnits.length"
                                >{{ $t("SEARCH") }}
                                </app-button-submit>
                                <app-button-reset :loading="loading_reset" @click="resetForm"
                                                  class="filter-submit-wrapper ml-3" :inline="true"
                                                  :disabled="!selectedUnits.length"></app-button-reset>
                            </b-col>
                        </b-row>
                        <div class="d-flex justify-content-end hidden_for_max_window_width">
                            <b-row>
                                <b-col cols="12" class="d-flex justify-content-end">
                                    <b-form-group class="mr-3 display-settings">
                                        <label>{{ $t('DISPLAY') }}</label>
                                        <app-select :search-empty-item="false" v-model="display" value-field="value"
                                                    text-field="text" set-first :options="display_options"></app-select>
                                    </b-form-group>
                                    <i class="fa fa-chevron-left availability_calendar--load-more "
                                       @click="loadMoreBack"
                                       aria-hidden="true"></i>
                                    <b-form-group class="mr-2 ml-2">
                                        <label>{{ $t('MONTH') }}</label>
                                        <app-month-picker :value="selectMonth"
                                                          @input="updateSelectMonth"></app-month-picker>
                                    </b-form-group>
                                    <i class="fa fa-chevron-right availability_calendar--load-more mr-3"
                                       aria-hidden="true"
                                       @click="loadMore">
                                    </i>

                                    <b-form-group class="mr-3 display-settings">
                                        <label>{{ $t('PERIOD') }}</label>
                                        <app-select :search-empty-item="false" v-model="display_month"
                                                    value-field="value"
                                                    text-field="text" set-first :options="month_options"
                                                    @input="updateSelectMonthPeriod"></app-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </b-form>
                <div v-if="propertyAvailabilityData.length > 0 && totalRows > 10"
                     class="d-flex mt-1  mb-3  align-items-end justify-content-end">
                    <div class="d-flex align-items-end" v-if="display_work_mode === MULTI_UNIT_VIEW">

                        <div class="d-flex align-items-center">
                            <span class="ml-3 mr-2">{{ $t("SHOWING") }}</span>
                            <app-select v-model="perPage" :search-empty-item="false"
                                        :options="computedLimitList"></app-select>
                            <span class="ml-2" v-html='$t("_RESULTS", {number: totalRows})'></span>
                        </div>
                    </div>
                </div>
                <div v-if="display_work_mode === MULTI_UNIT_VIEW">
                    <property-calendar-dates-test :selected-units="selected_unit"
                                                  :total-units-count="unitCount"
                                                  v-if="propertyAvailabilityData.length > 0"
                                                  @updateScrollLeft="updateScroll"
                                                  :load-more="load_more"
                                                  @showLoadMore="showLoadMore=$event"
                                                  @scroll="handleHorizontalScroll"
                                                  :showPropertyItem="showPropertyItem"
                                                  :scrollLeftPosition="scrollLeftPosition"
                                                  :showLoading="showLoading"
                                                  :display_work_mode="display_work_mode"
                                                  :dates="dateArray">
                    </property-calendar-dates-test>
                </div>
            </div>

            <div v-if="display_work_mode === MULTI_UNIT_VIEW">
                <property-calendar-wrapper-test v-if="propertyAvailabilityData.length > 0"
                                                :showPropertyItem="showPropertyItem"
                                                :dateRange="dateRange"
                                                :shiftKeyValue="shiftKeyVal"
                                                :scrollLeftPosition="scrollLeftPosition"
                                                :date-array="dateArray"
                                                :key="propertyAvailabilityObject.id+'_'+randomInt+'_'+key"
                                                :property="propertyAvailabilityObject"
                                                :selected-units="selected_unit"
                                                :display="display"
                                                :showLoading="showLoading"
                                                :display_work_mode="display_work_mode"
                                                :show_channel_data="show_channel_data"
                                                :currentDragDateProp="currentDragDate"
                                                :dragPeriodStartProp="dragPeriodStart"
                                                :clickedUnitRowIndex="clickedUnitRowIndex"
                                                v-for="(propertyAvailabilityObject, key) in propertyAvailabilityIndexedData">
                </property-calendar-wrapper-test>
            </div>

            <template v-for="monthString in monthArray" v-if="display_work_mode === MONTHLY_VIEW">
                <div class="availability_calendar mb-4">
                    <property-calendar-dates-test :selected-units="selected_unit"
                                                  v-if="propertyAvailabilityData.length > 0"
                                                  @updateScrollLeft="updateScrollObject(monthString, $event)"
                                                  :load-more="load_more"
                                                  @showLoadMore="showLoadMore=$event"
                                                  @scroll="handleHorizontalScroll($event, monthString)"
                                                  :showPropertyItem="showPropertyItem"
                                                  :scrollLeftPosition="byMonthLeftScrollbarPosition[monthString]"
                                                  :showLoading="showLoading"
                                                  :display_work_mode="display_work_mode"
                                                  :dates="getMonthDateArray(monthString)">
                    </property-calendar-dates-test>
                    <property-calendar-wrapper-test v-if="propertyAvailabilityData.length > 0"
                                                    :showPropertyItem="showPropertyItem"
                                                    :dateRange="{start: monthString + '-01', end: getMonthDateEnd(monthString)}"
                                                    :shiftKeyValue="shiftKeyVal"
                                                    :scrollLeftPosition="byMonthLeftScrollbarPosition[monthString]"
                                                    :date-array="getMonthDateArray(monthString)"
                                                    :key="propertyAvailabilityObject.id+'_'+randomInt+'_'+key"
                                                    :property="propertyAvailabilityObject"
                                                    :selected-units="selected_unit"
                                                    :display="display"
                                                    :showLoading="showLoading"
                                                    :display_work_mode="display_work_mode"
                                                    :show_channel_data="show_channel_data"
                                                    :isFirstMonth="isFirstMonth(monthString)"
                                                    :currentDragDateProp="currentDragDate"
                                                    :dragPeriodStartProp="dragPeriodStart"
                                                    :clickedUnitRowIndex="clickedUnitRowIndex"
                                                    v-for="(propertyAvailabilityObject, key) in propertyAvailabilityIndexedData">
                    </property-calendar-wrapper-test>
                </div>
            </template>

            <template v-if="display_work_mode === SINGLE_UNIT">
                <div v-if="propertyAvailabilityData.length > 0">
                    <div class="mini_calendar_holder_yearly">
                        <year-calendar-holder-test :property="singlePropertyObject"
                                                   :unit="singleUnitAvailabilityData"
                                                   :parent-unit="singleUnitAvailabilityData"
                                                   :is-contigent="singleUnitAvailabilityData.contigent"
                                                   :show-loader="loading"
                                                   :month="periodMonthStart"
                                                   :year="periodYearStart"
                                                   :shiftKeyValue="shiftKeyVal"
                                                   :currentDragDateProp="currentDragDate"
                                                   :dragPeriodStartProp="dragPeriodStart"
                                                   :clickedUnitRowIndex="clickedUnitRowIndex"
                                                   :show_channel_data="show_channel_data"
                                                   :number_of_months="display_month + 1">
                        </year-calendar-holder-test>
                    </div>
                </div>
                <div v-if="minWindowWidth && propertyAvailabilityData.length > 0">
                    <infinite-loading @infinite="infiniteHandler" direction="bottom" :distance="0">
                        <span slot="no-more"></span>
                    </infinite-loading>
                </div>
            </template>

            <b-pagination
                :disabled="showLoading"
                v-if="propertyAvailabilityData.length > 0 && totalRows > 10"
                @change="updatePagination"
                class="app_pagination mt-3"
                size="sm"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                :hide-goto-end-buttons="true">
                <template v-slot:prev-text>
                    <i class="fa fa-angle-left" aria-hidden="true"></i>
                </template>

                <template v-slot:next-text>
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                </template>
            </b-pagination>
            <div class="mt-3 availability_calendar-selectedContainer animated fadeIn" v-if="selected_unit_ids.length">
                <div>
                    <h4 v-if="selected_unit_ids.length > 1">{{
                            $t("ITEMS_SELECTED", {value: selected_unit_ids.length})
                        }}</h4>
                    <h4 v-else-if="selected_unit_ids.length === 1">{{ $t("ITEM_SELECTED") }}</h4>
                    <app-button @click="deselect" class="action_button" variant="link">{{ $t("DESELECT") }}
                    </app-button>
                </div>

                <div class="flex-grow-1 d-flex justify-content-end">
                    <app-button v-if="company.id === 1482" @click="openTagMapping" CLASS="mr-3">{{ $t('TAG') }}
                    </app-button>

                    <app-button :disabled="selected_unit_ids.length === 0" @click="groupEditAside = true"
                                class="pull-right mr-3">{{ $t('EDIT') }}
                    </app-button>
                    <app-button
                        v-if="checkPermission(C_RESERVATION_ACCOMMODATION_NEW) && selected_unit_ids.length === 1"
                        @click="newReservationAside = true" button_type="new" class="pull-right mr-3">
                        {{ $t('NEW_RESERVATION') }}
                    </app-button>
                </div>
            </div>
        </div>

        <calendar-edit-form
            mode="main-calendar-item-edit"
            :root-unit="aside_data.unit"
            :elements="view_options"
            :property-id="getUnitPropertyId(aside_data.unit)"
            :single-property="true">
        </calendar-edit-form>

        <app-aside v-model="open_new_res">
            <template slot="header">
                {{ $t('NEW_RESERVATION') }}
            </template>
            <new-accommodation-reservation-form-test :redirect="false" :property-id="resData.property"
                                                     :unit-id="resData.unit" :dateRange="resData.dateRange"
                                                     :availableUnitList="availableUnitList"
                                                     :onlyAvailableUnit="resData.dateRange ? true : false"
                                                     @created="createdReservation">
            </new-accommodation-reservation-form-test>
        </app-aside>
        <app-aside v-model="open_edit_res">
            <template slot="header">
                <reservation-accommodation-header :reservation="reservation"></reservation-accommodation-header>
            </template>

            <reservation-details-new v-if="reservation.id"
                                     :reservation-object="reservation"
                                     @saved="updateReservationData">
            </reservation-details-new>
        </app-aside>
        <app-aside v-model="aside_reservation" :widths="['col-lg-12','col-sm-12', '100%']" full-width
                   header-styles="position: sticky; top: 0; z-index: 999; background-color:#fff">
            <template slot="header">
                <reservation-accommodation-header :reservation="reservation"></reservation-accommodation-header>
            </template>

            <reservation-details v-if="reservation.id" :reservation-object="reservation"
                                 @saved="updateReservationData">
            </reservation-details>
        </app-aside>
        <app-aside v-model="advanced_filter" :widths="['col-lg-3', 'col-sm-12', '40%']">
            <template slot="header">
                {{ $t('AVAILABILITY_AND_QUOTE') }}
            </template>
            <advanced-filter :custom-tag-options="customTagOptions" :destination-tag-options="destinationTagOptions"
                             :loader="advanced_loader" @advancedSearch="searchAdvanced"></advanced-filter>
        </app-aside>
        <app-aside v-model="groupEditAside" :widths="['col-lg-4', 'col-md-5', 'col-sm-12', '40%']"
                   :main-classes="['p-0']">
            <template slot="header">{{ $t("EDIT") }}</template>
            <calendar-edit-form
                mode="calendar-edit"
                :selected-units="filteredSelectedUnitIdList"
                :property-id="selectedPropertyList.length === 1 ? selectedPropertyList[0] : null"
                :single-property="selectedPropertyList.length === 1"
                :contingent="selected_contingent_item"
                :periods="[dateRange]"
                @openNewReservation="groupEditAside = false">
            </calendar-edit-form>
        </app-aside>
        <app-aside v-model="tagAside" :widths="['col-lg-6','col-sm-12', '40%']">
            <template slot="header">
                {{ $t('TAG') }}
            </template>
            <tag-mapping @refreshData="fetchData({
                    start: dateRange.start,
                    end: dateRange.end
                }, false, true)" :entity_id="entity_id" :object_type_id="OBJECT_TYPE_UNIT"></tag-mapping>
        </app-aside>
    </div>

</template>

<script>
import moment from 'moment'
import YearCalendarHolderTest from "@/components/unit/calendar_opt/Table/YearCalendar/YearCalendarHolderTest";
import PropertyCalendarItemTest from "@/components/property/calendar/PropertyCalendarItemTest";
import PropertyCalendarDatesTest from "@/components/property/calendar/PropertyCalendarDatesTest";
import PropertyCalendarScroller from "@/components/property/calendar/PropertyCalendarScroller";
import PropertyCalendarAsideTest from "@/components/calendar/aside/PropertyCalendarAsideTest";
import AppButton from "@/components/app/AppButton/AppButton";
import loading from 'vue-full-loading'
import AppAside from "@/components/app/form/AppAside";
import {
    DEFAULT_PAGINATION_LIMIT,
    DEFAULT_UNIT_NUMBER_LIMIT,
    LS_PRESETS,
    MIN_WINDOW_WIDTH,
    MONTHLY_VIEW,
    MULTI_UNIT_VIEW,
    OBJECT_TYPE_UNIT,
    SINGLE_UNIT,
    TAG_TYPE_CUSTOM,
    TAG_TYPE_DESTINATION
} from '@/shared/constants';
import InfiniteLoading from "vue-infinite-loading";

import {
    EventBus,
    GE_CALENDAR_ADVANCED_FILTER,
    GE_CALENDAR_LOAD_ADD_MONTH,
    GE_CALENDAR_LOAD_AVAILABILITY,
    GE_CALENDAR_LOAD_PRICES,
    GE_CALENDAR_LOAD_RESTRICTIONS,
    GE_CALENDAR_OPEN_AVAILABILITY_PROPERTY,
    GE_CALENDAR_OPEN_AVAILABILITY_SIDEBAR,
    GE_CALENDAR_OPEN_RESERVATIONS,
    GE_CALENDAR_REFRESH_DATA,
    GE_CALENDAR_SELECT_ALL_UNIT,
    GE_CALENDAR_SELECT_UNIT,
    GE_CALENDAR_SINGLE_UNIT,
    GE_CALENDAR_UPDATE_AVAILABILITY_LIST,
    GE_OPEN_NEW_ACC_RES_ASIDE,
    GE_CALENDAR_CROSS_COMPONENT_AVAILABILITY_MIXIN_HANDLE_DRAG_CURRENT_PROP,
    GE_CALENDAR_CROSS_COMPONENT_AVAILABILITY_MIXIN_HANDLE_DRAG_PERIOD_START,
    GE_CALENDAR_CROSS_COMPONENT_AVAILABILITY_MIXIN_HANDLE_UNIT_ROW_INDEX,
} from "@/shared/EventBus";
import {
    C_PROPERTY_CALENDAR_AVAILIBILITY_V,
    C_PROPERTY_CALENDAR_PRICES_V,
    C_PROPERTY_CALENDAR_RESTRICTIONS_V,
    C_PROPERTY_CALENDAR_RM_SUGGESTION_V,
    C_PROPERTY_CALENDAR_ROOM_STATUS_V,
    C_RESERVATION_ACCOMMODATION_BOOKING_SITE,
    C_RESERVATION_ACCOMMODATION_NEW,
    MAIN_CALENDAR_FILTER_CITY,
    MAIN_CALENDAR_FILTER_GROUP,
    MAIN_CALENDAR_FILTER_PREDEFINED_CITY
} from "@/shared/component_permission";
import InboxReservation from "@/components/inbox/InboxReservation";
import CalendarAsideTest from "@/components/unit/calendar_opt/Aside/Calendar/CalendarAsideTest";
import ReservationAccommodationHeader from "@/components/reservation/accommodation/ReservationAccommodationHeader";
import ReservationDetails from "@/components/reservation/accommodation/ReservationDetails";
import ReservationDetailsNew from "@/components/reservation/accommodation/ReservationDetailsNew";
import {AC_FEATURE_SETUP, AC_MAIN_CALENDAR} from "@/mixins/AccessControl/AccessControlEnumeration";
import {fetchAvailability, getUnitAvailableList, getUnitList} from "@/services/unit";
import NewAccommodationReservationFormTest
    from "@/components/reservation/accommodation/forms/NewAccommodationReservationFormTest";
import AccommodationReservationFormEdit
    from "@/components/reservation/accommodation/forms/AccommodationReservationFormEdit";
import CalendarFilter from "@/components/calendar/CalendarFilter";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";
import CalendarYearCell from "@/components/property/calendar/CalendarYearCell";
import PropertyCalendarDateCell from "@/components/property/calendar/PropertyCalendarDateCell";
import {fetchAccommodationReservation} from "@/services/accommodation_reservation";
import AdvancedFilter from "@/components/property/calendar/Aside/AdvancedFilter";
import AppNoData from "@/components/app/AppNoData";
import MainCalendarGroupEdit from "@/components/unit/calendar_opt/Aside/Bulk/MainCalendarGroupEdit";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppDatePicker from "@/components/app/datetime/AppDatePicker";
import {toast} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import AppAccessControl from "@/components/app/AppAccessControl";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import AppMonthPicker from "@/components/app/datetime/AppMonthPicker";
import {getPresets} from "@/services/user";
import {getPropertyList} from "@/services/property";
import {fetchAccessControlData} from "@/services/access";
import AppButtonReset from "@/components/app/AppButton/AppButtonReset";
import TagMapping from "@/components/tag/TagMapping";
import {getRmFilterUnit} from "@/services/revenue_manager";
import PropertyCalendarWrapperTest from "@/components/property/calendar/PropertyCalendarWrapperTest";
import {getCityList} from "@/services/location";
import {getAllTags} from "@/services/tag";
import CalendarEditForm from "@/components/calendar/aside/CalendarEditForm";
import {cloneDeep} from "lodash";

export default {
    name: "CalendarWrapperTest",
    components: {
        CalendarEditForm,
        InfiniteLoading,
        PropertyCalendarWrapperTest,
        TagMapping,
        AppButtonReset,
        AppMonthPicker,
        AppAccessControl,
        AppDatePicker,
        AppSelect,
        MainCalendarGroupEdit,
        AppNoData,
        AdvancedFilter,
        PropertyCalendarDateCell,
        PropertyCalendarAsideTest,
        CalendarYearCell,
        AppDateRangePicker,
        AppButtonSubmit,
        CalendarFilter,
        NewAccommodationReservationFormTest,
        AccommodationReservationFormEdit,
        ReservationDetails,
        ReservationDetailsNew,
        ReservationAccommodationHeader,
        CalendarAsideTest,
        InboxReservation,
        AppAside,
        loading,
        AppButton, PropertyCalendarScroller, PropertyCalendarDatesTest, PropertyCalendarItemTest, YearCalendarHolderTest
    },
    data() {
        return {
            MIN_WINDOW_WIDTH,
            MAIN_CALENDAR_FILTER_GROUP,
            OBJECT_TYPE_UNIT,
            entity_id: [],
            cityList: [],
            tagAside: false,
            DEFAULT_PAGINATION_LIMIT, DEFAULT_UNIT_NUMBER_LIMIT,
            MAIN_CALENDAR_FILTER_CITY, AC_MAIN_CALENDAR, MAIN_CALENDAR_FILTER_PREDEFINED_CITY,
            limitList: [10, 20, 50],
            cellPixelsWide: 45.92,
            showLoading: false,
            loaded: false,
            loading: false,
            units: {},
            availability: {},
            filter: {
                currentPage: 0,
                limit: 5,
                sortBy: "property",
                sortDirection: "ASC",
            },
            group: null,
            total: 0,
            last_page: null,
            aside_reservation: false,
            reservation: {},
            shiftKeyVal: false,
            rootUnit: null,
            C_PROPERTY_CALENDAR_AVAILIBILITY_V: C_PROPERTY_CALENDAR_AVAILIBILITY_V,
            C_RESERVATION_ACCOMMODATION_NEW,
            MONTHLY_VIEW, MULTI_UNIT_VIEW, SINGLE_UNIT,
            openCalendarAside: false,
            propertyAvailabilityData: [],
            open_edit_res: false,
            open_new_res: false,
            resData: {
                unit: 0,
                property: null,
                dateRange: null
            },
            availableUnitList: [],
            dateRange: {
                start: null,
                end: null,
            },
            dateArray: [],
            monthArray: [],
            load_more: {
                value: false,
                load: false
            },
            calendarWrapper: null,
            randomInt: null,
            showLoadMore: false,
            showWrapper: false,
            scrollLeftPosition: 0,
            first_next_date: null,
            first_back_date: null,
            aside_data: {
                unit: null,
                los: [],
                occupancy: []
            },
            edit_aside: false,
            advanced_filter: false,
            advanced_loader: false,
            property_aside: false,
            unit_aside: false,
            selected_unit: [],
            advanced_res_request: null,
            advanced_filter_request: null,
            show_reservation: false,
            groupEditAside: false,
            display_options: [
                {text: this.$t("RATES"), value: 0},
                {text: this.$t("MIN_STAY"), value: 1},
            ],
            month_option: [0, 2, 5, 8, 11],
            display: 0,
            display_month: 0,
            display_month_max: 11, // 12 months
            infinite_load_month_step: 3,
            newReservationAside: false,
            selectDate: null,
            selectMonth: null,
            city: null,
            showPropertyItem: true,
            currentPage: 1,
            totalRows: 0,
            perPage: 50,
            preloadCityList: false,
            preloadPropertyList: false,
            preloadUnitList: false,
            property: null,
            unit: null,
            propertyList: [],
            show_channel_data: true,
            destinationTagOptions: [],
            customTagOptions: [],
            destination_tags: [],
            custom_tags: [],
            cities_count: 0,
            property_count: 0,
            root_unit_count: 0,
            property_unit_count: 0,
            loading_reset: false,
            reset: 0,
            access_control_fetch_key: AC_MAIN_CALENDAR,
            access_control_components: 1,
            year: moment().year(),
            month: moment().month(),
            room_status: {},
            days: [],
            unitList: [],
            selectedUnits: [],
            currentDragDate: null,
            dragPeriodStart: null,
            clickedUnitRowIndex: null,
            byMonthLeftScrollbarPosition: {},
            minWindowWidth: window.innerWidth <= MIN_WINDOW_WIDTH,
            infiniteLoaderState: null,
            handleInfiniteScroll: false,
            timeoutInfiniteScroll: false,
            extraUnitAvailabilityData: {},
            unitAvailabilityData: {},
        }
    },
    props: {
        display_work_mode: {
            type: Number,
            required: true
        }
    },
    mixins: [getErrorMessage, AccessControlComponent],
    computed: {
        unitCount() {
            let count = 0
            this.propertyAvailabilityIndexedData.forEach(property =>{
                if(property && property.hasOwnProperty('units')){
                    count += Object.keys(property.units).length
                    Object.keys(property.units).forEach(unitId => {
                        if(property.units[unitId] && property.units[unitId].hasOwnProperty('contigent') && property.units[unitId].contigent){
                            count += Object.keys(property.units[unitId].contigent).length + 1
                        }
                    })
                }
            })
            return count
        },
        periodMonthStart() {
            return moment(this.dateRange.start).month()
        },
        periodYearStart() {
            return moment(this.dateRange.start).year()
        },
        computedLimitList() {
            return this.limitList.sort((a, b) => a - b).map(el => {
                return {
                    id: el,
                    name: el
                }
            })
        },
        month_options() {
            return this.month_option.map(added_month => {
                return {
                    value: added_month,
                    text: `${added_month + 1} ${this.$tc('COUNTABLE.MONTH', added_month + 1)}`.toLowerCase()
                }
            })
        },
        selected_unit_ids() {
            return this.selected_unit.map(el => {
                return el.unit
            }).filter((value, index, self) => {
                // return unique values
                return self.indexOf(value) === index
            })
        },
        selected_contingent_item() {
            let contingentUnitNotSelected = true
            this.filteredSelectedUnitIdList.forEach(unitId => {
                contingentUnitNotSelected = contingentUnitNotSelected && this.selected_unit.findIndex(unit => unit.unit === unitId && unit.contingent_item === false) >= 0
            })
            return !contingentUnitNotSelected
            // return !!this.selected_unit.find(el => el.contingent_item === true)
        },
        selectedPropertyList() {
            let select_property_ids = []
            if (this.propertyAvailabilityData.length > 0) {
                let propAvailabilityList = this.propertyAvailabilityData.filter(el => el.hasOwnProperty('units') && Object.keys(el.units).length > 0)
                if (propAvailabilityList.length > 0) {
                    for (const propAvailabilityObject of propAvailabilityList) {
                        for (const [key, value] of Object.entries(propAvailabilityObject.units)) {
                            if (this.selected_unit.find(el => el.unit === (Number(key)))) {
                                if (select_property_ids.includes(Number(propAvailabilityObject.id)) === false) {
                                    select_property_ids.push(Number(propAvailabilityObject.id))
                                }
                            }
                        }
                    }
                }
            }
            return select_property_ids
        },
        filteredSelectedUnitIdList() {
            let selectedUnitIdList = cloneDeep(this.selected_unit_ids)
            if (this.propertyAvailabilityData.length > 0) {
                let propAvailabilityList = this.propertyAvailabilityData.filter(el => el.hasOwnProperty("units") && Object.keys(el.units).length > 0)
                if (propAvailabilityList.length > 0) {
                    for (const propAvailabilityObject of propAvailabilityList) {
                        this.selected_unit_ids.forEach(unitId => {
                            if (
                                propAvailabilityObject.units
                                && propAvailabilityObject.units.hasOwnProperty(unitId)
                                && propAvailabilityObject.units[unitId]
                                && propAvailabilityObject.units[unitId].hasOwnProperty("contigent")
                                && propAvailabilityObject.units[unitId].contigent
                            ) {
                                let contingentUnitIdList = Object.keys(propAvailabilityObject.units[unitId].contigent)
                                let containsAllContingentUnits = true
                                contingentUnitIdList.forEach(contingentUnitId => {
                                    contingentUnitId = Number(contingentUnitId)
                                    containsAllContingentUnits = containsAllContingentUnits && selectedUnitIdList.indexOf(contingentUnitId) >= 0
                                })
                                if (containsAllContingentUnits) {
                                    contingentUnitIdList.forEach(contingentUnitId => {
                                        contingentUnitId = Number(contingentUnitId)
                                        selectedUnitIdList.splice(selectedUnitIdList.indexOf(contingentUnitId), 1)
                                    })
                                }
                            }
                        })
                    }
                }
            }
            return selectedUnitIdList
        },
        showMoreBack() {
            return this.scrollLeftPosition === 0
        },
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        lastIndexDate() {
            return this.dateArray.length > 0 ? this.dateArray.length - 1 : null
        },
        view_options() {
            let options = []
            if (this.availabilityViewPermission) {
                options.push(C_PROPERTY_CALENDAR_AVAILIBILITY_V)
            }
            if (this.roomStatusViewPermission) {
                options.push(C_PROPERTY_CALENDAR_ROOM_STATUS_V)
            }
            if (this.pricesViewPermission) {
                options.push(C_PROPERTY_CALENDAR_PRICES_V)
            }
            if (this.restrictionsViewPermission) {
                options.push(C_PROPERTY_CALENDAR_RESTRICTIONS_V)
            }
            return options
        },
        availabilityViewPermission() {
            return this.$store.getters['user/getPermission'](C_PROPERTY_CALENDAR_AVAILIBILITY_V)
        },
        pricesViewPermission() {
            return this.$store.getters['user/getPermission'](C_PROPERTY_CALENDAR_PRICES_V)
        },
        restrictionsViewPermission() {
            return this.$store.getters['user/getPermission'](C_PROPERTY_CALENDAR_RESTRICTIONS_V)
        },
        rmSuggestionViewPermission() {
            return this.$store.getters['user/getPermission'](C_PROPERTY_CALENDAR_RM_SUGGESTION_V)
        },
        roomStatusViewPermission() {
            return this.$store.getters['user/getPermission'](C_PROPERTY_CALENDAR_ROOM_STATUS_V)
        },
        unitMultiselectDisabled() {
            return this.property_unit_count > 1 &&
                (
                    !this.property ||
                    (
                        !Array.isArray(this.property) && typeof this.property === 'object' &&
                        (
                            !this.property.hasOwnProperty('id') ||
                            !this.property.id
                        )
                    ) ||
                    (
                        Array.isArray(this.property) &&
                        this.property.length === 0
                    )
                )
        },
        singleUnitAvailabilityData() {
            if (this.propertyAvailabilityData.length > 0 && Object.keys(this.unitAvailabilityData).length === 0) {
                const propertyAvailabilityData = this.propertyAvailabilityData.find(property => true)
                if (propertyAvailabilityData && propertyAvailabilityData.units && Object.keys(propertyAvailabilityData.units).length > 0) {
                    this.unitAvailabilityData = Object.values(propertyAvailabilityData.units).find(unit => true)
                }
            }

            if (this.unitAvailabilityData && Object.keys(this.unitAvailabilityData).length > 0) {
                if (this.extraUnitAvailabilityData && Object.keys(this.extraUnitAvailabilityData).length > 0) {
                    const relevant_dates_list = this.dateArray.map(el => {
                        return el.format('YYYY-MM-DD')
                    })

                    if (this.extraUnitAvailabilityData.data && Object.keys(this.extraUnitAvailabilityData.data).length > 0) {
                        let availability_list = []
                        Object.entries(this.unitAvailabilityData.data).forEach(([date, availability]) => {
                            if (relevant_dates_list.includes(date)) {
                                availability_list[date] = availability
                            }
                        })
                        Object.entries(this.extraUnitAvailabilityData.data).forEach(([date, availability]) => {
                            availability_list[date] = availability
                        })
                        this.unitAvailabilityData.data = {...availability_list}
                    }

                    if (this.extraUnitAvailabilityData.reservation && Object.keys(this.extraUnitAvailabilityData.reservation).length > 0) {
                        let reservation_list = []
                        Object.entries(this.unitAvailabilityData.reservation).forEach(([reservation_id, reservation]) => {
                            reservation_list[reservation_id] = reservation
                        })
                        Object.entries(this.extraUnitAvailabilityData.reservation).forEach(([reservation_id, reservation]) => {
                            reservation_list[reservation_id] = reservation
                        })
                        this.unitAvailabilityData.reservation = {...reservation_list}
                    }

                    this.extraUnitAvailabilityData = {}
                }
            }

            return {
                ...this.unitAvailabilityData || {},
                contigent: this.unitAvailabilityData && this.unitAvailabilityData.hasOwnProperty('contigent') && this.unitAvailabilityData.contigent !== null
            }
        },
        singlePropertyObject() {
            let propertyAvailabilityData = {id: null, name: null}
            if (this.propertyAvailabilityData.length > 0) {
                propertyAvailabilityData = this.propertyAvailabilityData.find(property => true)
            }

            return {
                id: propertyAvailabilityData.id,
                name: propertyAvailabilityData.name,
            }
        },
        propertyAvailabilityDataIndexPerUnitList() {
            let return_list = []

            Object.entries(this.propertyAvailabilityData).forEach(([propertyAvailabilityDataIndex, propertyAvailabilityObject]) => {
                if (propertyAvailabilityObject && propertyAvailabilityObject.hasOwnProperty('units') && Object.keys(propertyAvailabilityObject.units).length > 0) {
                    const unitAvailabilityData = Object.values(propertyAvailabilityObject.units).find(unit => true)
                    if (unitAvailabilityData && unitAvailabilityData.hasOwnProperty('id') && unitAvailabilityData.id) {
                        return_list[parseInt(unitAvailabilityData.id)] = {
                            property: propertyAvailabilityObject.id,
                            root_unit: unitAvailabilityData.id,
                            index: propertyAvailabilityDataIndex
                        }
                        if (unitAvailabilityData.hasOwnProperty('contigent') && unitAvailabilityData.contigent && Object.keys(unitAvailabilityData.contigent).length > 0) {
                            Object.keys(unitAvailabilityData.contigent).forEach(contigentUnitId => {
                                return_list[parseInt(contigentUnitId)] = {
                                    property: propertyAvailabilityObject.id,
                                    root_unit: unitAvailabilityData.id,
                                    index: propertyAvailabilityDataIndex
                                }
                            })
                        }
                    }
                }
            })

            return return_list
        },
        propertyAvailabilityIndexedData() {
            let return_list = []

            let unitRowIndex = -1
            Object.entries(this.propertyAvailabilityData).forEach(([propertyAvailabilityDataIndex, propertyAvailabilityObject]) => {
                return_list[propertyAvailabilityDataIndex] = propertyAvailabilityObject

                if (propertyAvailabilityObject.hasOwnProperty('units') && Object.keys(propertyAvailabilityObject.units).length > 0) {
                    Object.entries(propertyAvailabilityObject.units).forEach(([unitId, unitAvailabilityObject]) => {
                        unitRowIndex = unitRowIndex + 1
                        this.$set(return_list[propertyAvailabilityDataIndex].units[unitId], 'index', unitRowIndex)

                        if (unitAvailabilityObject.hasOwnProperty('contigent') && unitAvailabilityObject.contigent && Object.keys(unitAvailabilityObject.contigent).length > 0) {
                            unitRowIndex = unitRowIndex + 1
                            this.$set(return_list[propertyAvailabilityDataIndex].units[unitId], 'contigent_index', unitRowIndex)

                            Object.keys(unitAvailabilityObject.contigent).forEach(contigentUnitId => {
                                unitRowIndex = unitRowIndex + 1
                                this.$set(return_list[propertyAvailabilityDataIndex].units[unitId].contigent[contigentUnitId], 'contigent_index', unitRowIndex)
                            })
                        }
                    })
                }
            })
            return return_list
        },
    },
    methods: {
        isFirstMonth(monthString) {
            return moment(this.dateRange.start).format('YYYY-MM') === monthString
        },
        infiniteHandler($state) {
            this.infiniteLoaderState = $state;

            if (!this.timeoutInfiniteScroll && !this.loading && !this.handleInfiniteScroll && this.display_work_mode === SINGLE_UNIT && window.innerWidth <= MIN_WINDOW_WIDTH) {
                this.timeoutInfiniteScroll = true;
                setTimeout(() => {
                    this.handleInfiniteScroll = true;
                    this.timeoutInfiniteScroll = false;
                    this.display_month += this.infinite_load_month_step;

                    this.fetchData({
                        start: moment(this.dateRange.end).add(1, 'days').startOf('month').format("YYYY-MM-DD"),
                        end: moment(this.dateRange.end).startOf('month').add(this.infinite_load_month_step, 'months').endOf('month').format("YYYY-MM-DD"),
                    }, false);
                }, 1000);
            }
        },
        defaultWorkMode() {
            if (this.root_unit_count > 2 && this.root_unit_count <= DEFAULT_UNIT_NUMBER_LIMIT) {
                this.display_month = 2
            } else {
                this.display_month = 5
            }

            if (window.innerWidth <= MIN_WINDOW_WIDTH) {
                this.display_month = 2
            } else if (this.display_work_mode === SINGLE_UNIT) {
                this.display_month = 11
            }
        },
        searchFilter(value) {
            const req = {
                title: value,
                page: 1,
                perPage: 100,
                company: this.company.id
            }
            return getRmFilterUnit(req).then(response => ({data: response.data.items}))
        },
        openTagMapping() {
            this.entity_id = this.selected_unit.length > 0 ? this.selected_unit.filter(el => el.contingent_item === false).map(el => {
                return el.unit
            }) : []
            if (Number(this.company.id) === 1482) {
                this.tagAside = true
            }
        },
        searchProperty(value) {
            let request = {name: value, company_id: this.company.id, has_units: 1}
            if (this.city !== null) {
                const city = typeof this.city === 'object' && this.city.hasOwnProperty('id') ? this.city.id : this.city
                request = {...request, ...{city}}
            }
            return getPropertyList(request).then(response => {
                return {data: response.data.items}
            })
        },
        searchUnitList(value) {
            return getUnitList({name: value, order_by: this.filter.sortBy}).then(response => {
                return {data: response.data.items}
            })
        },
        searchCity(value) {
            return getCityList({
                name: value,
            })
        },
        updatePagination(value) {
            this.currentPage = value
            if (this.advanced_filter_request === null) {
                this.fetchData({
                    start: this.dateRange.start,
                    end: this.dateRange.end,
                }, false, true)
            } else {
                let req = {...this.advanced_filter_request, ...{currentPage: this.currentPage}}
                this.searchAdvanced(req)
            }
        },
        updateSelectMonth(value) {
            if (value) {
                this.dateRange.start = moment(value).startOf('month').format("YYYY-MM-DD")
                this.dateRange.end = moment(value).add(this.display_month, 'months').endOf('month').format("YYYY-MM-DD")
                this.selectMonth = value
                if (this.propertyAvailabilityData.length > 0) {
                    this.fetchData({start: this.dateRange.start, end: this.dateRange.end}, true, true)
                }
            }
        },
        updateSelectMonthPeriod() {
            this.dateRange.end = moment(this.dateRange.start).add(this.display_month, 'months').endOf('month').format("YYYY-MM-DD")
            if (this.propertyAvailabilityData.length > 0) {
                this.fetchData({start: this.dateRange.start, end: this.dateRange.end}, true, true)
            }
        },
        deselect() {
            this.selected_unit = []
        },
        updateScroll($event) {
            this.scrollLeftPosition = $event
        },
        updateScrollObject(key, value) {
            this.$set(this.byMonthLeftScrollbarPosition, key, value)
        },
        onScroll($event) {
            this.scrollLeftPosition = $event.target.scrollLeft
        },
        getMonthDateArray(month) {
            let return_list = [];
            this.dateArray.forEach(date => {
                if (date.format('YYYY-MM') === month) {
                    return_list.push(moment(date))
                }
            })
            return return_list
        },
        getMonthDateEnd(month) {
            return moment(month + '-01').endOf('month').format("YYYY-MM-DD")
        },
        setDateArray() {
            this.dateArray = [];
            this.monthArray = [];
            let currentDate = moment(this.dateRange.start);
            const stopDate = moment(this.dateRange.end);
            while (currentDate <= stopDate) {
                this.$set(this.byMonthLeftScrollbarPosition, moment(currentDate).format('YYYY-MM'), 0)
                if (this.monthArray.includes(moment(currentDate).format('YYYY-MM')) === false) {
                    this.monthArray.push(moment(currentDate).format('YYYY-MM'))
                }
                this.dateArray.push(moment(currentDate))
                currentDate = moment(currentDate).add(1, 'days');
            }
        },
        updateRange(value) {
            this.dateRange.start = value.start
            this.dateRange.end = value.end
            this.fetchData(value, false, true)
        },
        loadMore() {
            this.selectMonth = moment(this.selectMonth).add(1, 'months').format("YYYY-MM-DD")
            this.dateRange.start = moment(this.selectMonth).startOf('month').format("YYYY-MM-DD")
            if (this.display_work_mode === MULTI_UNIT_VIEW) {
                this.dateRange.end = moment(this.selectMonth).endOf('month').format("YYYY-MM-DD")
            } else if (this.display_work_mode === MONTHLY_VIEW || this.display_work_mode === SINGLE_UNIT) {
                this.dateRange.end = moment(this.dateRange.start).add(this.display_month, 'months').endOf('month').format("YYYY-MM-DD")
            }
            if (this.propertyAvailabilityData.length > 0) {
                this.showWrapper = false
                this.first_next_date = this.dateRange.start
                this.first_back_date = null
                this.loaded = false
                this.fetchData({start: this.dateRange.start, end: this.dateRange.end}, true, true)
            }
        },
        loadMoreBack() {
            this.selectMonth = moment(this.selectMonth).subtract(1, 'months').format("YYYY-MM-DD")
            this.dateRange.start = moment(this.selectMonth).startOf('month').format("YYYY-MM-DD")
            if (this.display_work_mode === MULTI_UNIT_VIEW) {
                this.dateRange.end = moment(this.selectMonth).endOf('month').format("YYYY-MM-DD")
            } else if (this.display_work_mode === MONTHLY_VIEW || this.display_work_mode === SINGLE_UNIT) {
                this.dateRange.end = moment(this.dateRange.start).add(this.display_month, 'months').endOf('month').format("YYYY-MM-DD")
            }
            if (this.propertyAvailabilityData.length > 0) {
                this.first_back_date = this.dateRange.start
                this.first_next_date = null
                this.showWrapper = false
                this.loaded = false
                this.fetchData({start: this.dateRange.start, end: this.dateRange.end}, true, true)
            }
        },
        handleHorizontalScroll($event, key = null) {
            if (key === null) {
                this.scrollLeftPosition = $event
            } else {
                this.$set(this.byMonthLeftScrollbarPosition, key, $event)
            }
        },
        updateReservationData(data) {
            this.aside_reservation = false
            this.fetchDataByProperty({unit: [data.unit.id, this.reservation.unit.id]})
        },
        openNewReservation(request) {
            this.resData.dateRange = null
            this.resData.property = null
            this.resData.unit = null

            if (request && typeof request === 'object') {
                this.resData.property = request.hasOwnProperty('property') ? request.property : null
                this.resData.dateRange = request.hasOwnProperty('dateRange') ? request.dateRange : null
                this.resData.unit = request.hasOwnProperty('unit_id') ? request.unit_id : null
            } else {
                if (this.selected_unit.length === 1) {
                    this.resData.unit = this.selected_unit[0] ? this.selected_unit[0].unit : null
                    if (this.resData.unit) {
                        const propertyIndex = this.propertyAvailabilityData.findIndex(el => Object.keys(el.units).map(key => parseInt(key)).includes(this.resData.unit))
                        if (propertyIndex < 0) {
                            return
                        }
                        this.resData.property = this.propertyAvailabilityData[propertyIndex] ? this.propertyAvailabilityData[propertyIndex].id : null
                    }
                }
            }

            if (this.resData.dateRange) {
                getUnitAvailableList({
                    property: this.resData.property,
                    date_from: this.resData.dateRange ? this.resData.dateRange.start : null,
                    date_to: this.resData.dateRange ? this.resData.dateRange.end : null
                }).then(response => {
                    this.availableUnitList = response.data
                    if (response.data.length > 0) {
                        let selected_available_unit = false
                        if (this.resData.unit) {
                            if (response.data.find(el => el.id === this.resData.unit)) {
                                selected_available_unit = true
                            } else if (this.propertyAvailabilityDataIndexPerUnitList.length && this.propertyAvailabilityDataIndexPerUnitList.hasOwnProperty(this.resData.unit)) {
                                const root_unit = this.propertyAvailabilityDataIndexPerUnitList[this.resData.unit].root_unit

                                Object.entries(this.propertyAvailabilityDataIndexPerUnitList).forEach(([unitId, unitPropertyIndexObject]) => {
                                    if (!selected_available_unit && unitPropertyIndexObject.root_unit === root_unit) {
                                        if (response.data.find(el => el.id === unitId)) {
                                            selected_available_unit = true
                                            this.resData.unit = unitId
                                        }
                                    }
                                })
                            }
                        }

                        if (!selected_available_unit) {
                            this.resData.unit = response.data[0].id
                        }
                    }
                }, error => {
                    this.availableUnitList = []
                }).finally(() => {
                    this.open_new_res = true
                })
            } else {
                this.availableUnitList = []
                this.open_new_res = true
            }
        },
        createdReservation(reservation) {
            this.open_new_res = false
            this.fetchDataByProperty({
                unit: reservation.unit.id,
            })
        },
        fetchData: function (requestObject, lm, global_loader) {
            EventBus.$emit(GE_CALENDAR_SINGLE_UNIT, {propertyId: null, unitId: null})
            this.load_more = lm ? {value: true, load: false} : {value: false, load: false}
            this.selected_unit = []
            this.advanced_filter_request = null
            let req = {
                date_from: requestObject.start,
                date_to: requestObject.end,
                order_by: this.filter.sortBy,
                order_by_direction: this.filter.sortDirection,
                property: requestObject.hasOwnProperty('property') ? requestObject.property : null,
                company: this.company.id,
                city: this.city ? typeof this.city === 'object' && this.city.hasOwnProperty('id') ? this.city.id : this.city : null,
                perPage: requestObject.hasOwnProperty('perPage') ? requestObject.perPage : this.perPage,
                currentPage: this.currentPage,
                actions: this.display === 1 ? ['restriction'] : ['rate_default'],
            }
            if (this.destination_tags.length > 0) {
                req = {...req, ...{destination: this.destination_tags}}
            }
            if (this.custom_tags.length > 0) {
                req = {...req, ...{custom_tag: this.custom_tags}}
            }

            if (this.property) {
                req = {...req, ...{property: typeof this.property === 'object' && this.property.hasOwnProperty('id') ? this.property.id : this.property}}
            }
            if (this.group && typeof this.group === 'object' && this.group.hasOwnProperty('id')) {
                req = {...req, ...{filter_id: this.group.id}}
            }

            if (this.selectedUnits.length > 0) {
                req.unit = this.selectedUnits
            } else if (this.selectedUnits && typeof this.selectedUnits === 'number') {
                req.unit = this.selectedUnits
            }
            if (global_loader) {
                this.showLoading = true
            } else {
                if (this.reset === 1) {
                    this.loading = false
                    this.loading_reset = true
                } else {
                    this.loading = true
                    this.loading_reset = false
                }
            }
            fetchAvailability(req).then(response => {
                if (!response.data || !response.data.hasOwnProperty('items')) {
                    this.totalRows = 0
                    this.propertyAvailabilityData = []
                } else {
                    this.setRandomInt()
                    this.totalRows = response.data.total
                    this.propertyAvailabilityData = response.data.items
                    this.dateRange.end = requestObject.end
                    this.setDateArray()
                }
            }, error => {
                this.totalRows = 0
                this.propertyAvailabilityData = []
                toast({
                    'title': this.$t('NOTIFICATIONS.ERROR'),
                    'message': this.getMessages(error),
                    'type': 'error',
                    'timeout': 3000
                })
            }).finally(() => {
                this.showWrapper = true
                this.show_reservation = false
                this.loaded = true
                this.load_more = lm ? {value: true, load: true} : {value: false, load: false}
                this.showLoading = false
                this.loading = false
                this.loading_reset = false
                this.reset = 0

                this.handleSingleUnitLoad()
            })
        },
        fetchDataByProperty(requestObject) {
            this.load_more = {value: false, load: false}
            this.open_new_res = false
            this.loading = false

            if (this.propertyAvailabilityData.length) {
                if (this.display_work_mode === SINGLE_UNIT) {
                    this.fetchData({
                        start: this.dateRange.start,
                        end: this.dateRange.end
                    }, false, true)
                } else {
                    // reload calendar data only for visible multi-unit calendar rows/units
                    let req = {
                        date_from: this.dateRange.start,
                        date_to: this.dateRange.end,
                        order_by: this.filter.sortBy,
                        order_by_direction: this.filter.sortDirection,
                        company: this.company.id,
                        currentPage: 1,
                        perPage: this.propertyAvailabilityData.length,
                        actions: this.display === 1 ? ['restriction'] : ['rate_default'],
                    }

                    if (requestObject && typeof requestObject === 'object' && requestObject.hasOwnProperty('unit') && requestObject.unit) {
                        req.unit = requestObject.unit
                    } else {
                        req.unit = []
                        req.property = []
                        Object.entries(this.propertyAvailabilityDataIndexPerUnitList).forEach(([unitId, unitPropertyIndexObject]) => {
                            if (!req.unit.includes(unitPropertyIndexObject.root_unit)) {
                                req.unit.push(unitPropertyIndexObject.root_unit)
                            }
                            if (!req.property.includes(unitPropertyIndexObject.property)) {
                                req.property.push(unitPropertyIndexObject.property)
                            }
                        })
                    }

                    this.showLoading = true
                    fetchAvailability(req).then(response => {
                        if (response.data && response.data.hasOwnProperty('items') && response.data.items.length > 0) {
                            let replacedIndexList = []
                            response.data.items.forEach(responseItem => {
                                if (responseItem && responseItem.hasOwnProperty('id') && responseItem.hasOwnProperty('units') && responseItem.id && responseItem.units) {
                                    Object.entries(this.propertyAvailabilityDataIndexPerUnitList).forEach(([unitId, unitPropertyIndexObject]) => {
                                        if (!replacedIndexList.includes(unitPropertyIndexObject.index) && Object.keys(responseItem.units).map(key => parseInt(key)).includes(parseInt(unitId))) {
                                            // replace specific multi-unit calendar row
                                            replacedIndexList.push(unitPropertyIndexObject.index)
                                            this.$set(this.propertyAvailabilityData, unitPropertyIndexObject.index, responseItem)
                                        }
                                    })
                                }
                            })
                        }
                    }, error => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.ERROR'),
                            'message': this.getMessages(error),
                            'type': 'error',
                            'timeout': 3000
                        })
                    }).finally(() => {
                        this.loading = false
                        this.showLoading = false
                        this.show_reservation = false
                    })
                }
            }
        },
        search() {
            this.currentPage = 1
            this.loaded = false
            this.showWrapper = false
            let req = {
                start: this.dateRange.start,
                end: this.dateRange.end,
                city: this.city,
                perPage: this.perPage,
                company: this.company.id
            }
            if (this.property) {
                req = {...req, ...{property: typeof this.property === 'object' && this.property.hasOwnProperty('id') ? this.property.id : this.property}}
            }

            this.fetchData(req, false)
        },
        getCities() {
            getCityList({
                user_cities: true,
            }).then(response => {
                this.cityList = response.data
            })
        },
        getRange(date) {
            const start = moment(date.start).add(-7, 'days').format("YYYY-MM-DD")
            const end = moment(date.end).add(+7, 'days').format("YYYY-MM-DD")
            return {
                start,
                end
            }
        },
        searchAdvanced(request) {
            this.selected_unit = []
            this.loaded = false
            this.advanced_loader = true
            let period_range = this.dateRange
            if (request.check_in) {
                period_range = this.getRange({start: request.check_in, end: request.check_out})
                this.dateRange = period_range
            }
            this.showWrapper = false
            this.advanced_filter_request = {
                ...{
                    date_from: period_range.start,
                    date_to: period_range.end,
                    availability_quote_filter: true,
                    actions: this.display === 1 ? ['restriction'] : ['rate_default'],
                    perPage: this.perPage
                }, ...request
            }

            this.setDateArray()
            fetchAvailability(this.advanced_filter_request).then(response => {
                this.setRandomInt()
                if (typeof response.data === 'object' && response.data.hasOwnProperty('items')) {
                    this.propertyAvailabilityData = response.data.items
                    this.totalRows = response.data.total
                } else {
                    this.propertyAvailabilityData = []
                    this.totalRows = 0
                }
                if (this.propertyAvailabilityData.length === 1) {
                    let propertyFilteredData = this.propertyAvailabilityData.filter(el => el.hasOwnProperty('units') && Object.keys(el.units).length === 1)
                    if (propertyFilteredData.length === 1) {
                        const propertyObjectData = propertyFilteredData[0]
                        const unit_list = Object.keys(propertyObjectData.units)
                        this.advanced_res_request = {
                            property: propertyObjectData.id,
                            dateRange: {
                                start: request.check_in,
                                end: request.check_out,
                            },
                            unit: unit_list[0]
                        }
                        setTimeout(() => {
                            this.selected_unit.push({
                                unit: Number(this.advanced_res_request.unit),
                                contingent_item: false
                            })
                            this.show_reservation = true
                        }, 200)
                    }
                }
                this.showWrapper = true
                this.advanced_filter = false
            }, error => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.getMessages(error),
                    'type': 'error',
                    'timeout': 3000
                })
            }).finally(() => {
                this.advanced_loader = false
                this.loaded = true
            })
        },
        shiftKeyDown(e) {
            if (e.key === 'Shift') {
                this.shiftKeyVal = true
            }
        },
        shiftKeyUp(e) {
            if (e.key === 'Shift') {
                this.shiftKeyVal = false
                this.currentDragDate = null
                this.dragPeriodStart = null
            }
        },
        onResizeHandler() {
            const previousMinWindowWidthValue = this.minWindowWidth
            this.minWindowWidth = window.innerWidth <= MIN_WINDOW_WIDTH
            if (this.display_work_mode === SINGLE_UNIT) {
                if (this.minWindowWidth) {
                    if (this.display_month !== 2 && this.minWindowWidth !== previousMinWindowWidthValue) {
                        this.display_month = 2;
                        if (this.propertyAvailabilityData.length) {
                            this.fetchData({
                                start: moment(this.dateRange.start).set({
                                    year: this.year,
                                    month: this.selectMonth
                                }).startOf('month').format("YYYY-MM-DD"),
                                end: moment(this.dateRange.start).add(this.display_month, 'months').endOf('month').format("YYYY-MM-DD"),
                            }, false);
                        }
                    }
                } else {
                    if (this.display_month !== 11 && this.minWindowWidth !== previousMinWindowWidthValue) {
                        this.display_month = 11
                        if (this.propertyAvailabilityData.length) {
                            this.fetchData({
                                start: moment(this.dateRange.start).set({
                                    year: this.year,
                                    month: this.selectMonth
                                }).startOf('month').format("YYYY-MM-DD"),
                                end: moment(this.dateRange.start).add(this.display_month, 'months').endOf('month').format("YYYY-MM-DD"),
                            }, false);
                        }
                    }
                }
            }
        },
        setRandomInt() {
            this.randomInt = Math.floor(Math.random() * 10)
        },
        getUnitAdditionalData(property, unit) {
            if (property && property.hasOwnProperty('id') && unit && unit.hasOwnProperty('id')) {
                let propertyDataList = this.propertyAvailabilityData.filter(el => el.id === property.id)
                for (const propertyData of propertyDataList) {
                    if (propertyData && propertyData.hasOwnProperty('units') && Object.keys(propertyData.units).length > 0) {
                        let unitData = propertyData.units[unit.id]
                        if (typeof unitData === 'object' && Object.keys(unitData).length > 0) {
                            return unitData && unitData.hasOwnProperty('additional_data') ? unitData.additional_data : null
                        }
                    }
                }
            }
            return null
        },
        getTagList(type) {
            getAllTags(type, {company: this.company.id}).then(response => {
                if (type === TAG_TYPE_DESTINATION) {
                    this.destinationTagOptions = response.data
                }
                if (type === TAG_TYPE_CUSTOM) {
                    this.customTagOptions = response.data
                }
            })
        },
        resetForm() {
            this.propertyAvailabilityData = []
            this.extraUnitAvailabilityData = {}
            this.unitAvailabilityData = {}
            this.property = null
            this.selectedUnits = []
            this.city = []
            this.custom_tags = []
            this.destination_tags = []
            this.group = null
            this.currentPage = 1
            this.advanced_filter_request = null

            if (this.display_work_mode !== SINGLE_UNIT) {
                this.reset = 1
                this.search()
            }
        },
        updateSelectedUnit(unitId) {
            if (this.selectedUnits.length > 0) {
                this.selectedUnits.splice(0)
            }
            this.selectedUnits.push(unitId)
        },
        handleSingleUnitLoad() {
            let handledSingleUnitLoad = false
            if (this.display_work_mode === SINGLE_UNIT && this.display_month > this.display_month_max && this.propertyAvailabilityData.length > 0) {
                this.display_month = this.display_month_max
                this.dateRange.start = moment(this.dateRange.end).startOf('month').subtract(this.display_month_max, 'months').startOf('month').format("YYYY-MM-DD")
                this.setDateArray()
            }

            if (this.propertyAvailabilityData.length > 0) {
                const propertyAvailabilityData = this.propertyAvailabilityData.find(property => true)
                if (propertyAvailabilityData && propertyAvailabilityData.units && Object.keys(propertyAvailabilityData.units).length > 0) {
                    const unitAvailabilityData = Object.values(propertyAvailabilityData.units).find(unit => true)
                    if (this.propertyAvailabilityData.length === 1 && Object.keys(propertyAvailabilityData.units).length === 1) {
                        const unitId = !unitAvailabilityData.contigent ? unitAvailabilityData.id : null
                        EventBus.$emit(GE_CALENDAR_SINGLE_UNIT, {
                            propertyId: propertyAvailabilityData.id,
                            unitId: unitId
                        })
                    }

                    if (this.display_work_mode === SINGLE_UNIT) {
                        handledSingleUnitLoad = true
                        if (this.handleInfiniteScroll) {
                            this.handleInfiniteScroll = false
                            if (this.infiniteLoaderState !== null) {
                                this.infiniteLoaderState.loaded();
                            }
                            if (Object.keys(this.unitAvailabilityData).length > 0) {
                                this.extraUnitAvailabilityData = unitAvailabilityData
                            } else {
                                this.unitAvailabilityData = unitAvailabilityData
                                this.extraUnitAvailabilityData = {}
                            }
                        } else {
                            this.unitAvailabilityData = unitAvailabilityData
                            this.extraUnitAvailabilityData = {}
                        }
                    }
                }
            }

            if (!handledSingleUnitLoad && this.display_work_mode === SINGLE_UNIT) {
                if (this.handleInfiniteScroll) {
                    this.handleInfiniteScroll = false
                    this.extraUnitAvailabilityData = {}
                    if (this.infiniteLoaderState !== null) {
                        this.infiniteLoaderState.loaded();
                    }
                } else {
                    this.unitAvailabilityData = {}
                    this.extraUnitAvailabilityData = {}
                }
            }
        },
        getUnitPropertyId(unit) {
            if (!unit || typeof unit !== "object") {
                return null
            }
            let propertyId = unit.hasOwnProperty("property") && unit.property && typeof unit.property === "object" && unit.property.hasOwnProperty("id") ? unit.property.id : null
            propertyId = !propertyId && unit.hasOwnProperty("property_id") ? unit.property_id : propertyId
            return propertyId
        },
        findParentUnitData(target_unit_id) {
            let parent_unit_id = null
            let unit_list = []
            if (this.propertyAvailabilityData.length > 0) {
                this.propertyAvailabilityData.forEach(el => {
                    if (el.hasOwnProperty('units') && Object.keys(el.units).length > 0) {
                        for (const [unit_id, unitDataObject] of Object.entries(el.units)) {
                            if (Number(unit_id) === Number(target_unit_id)) {
                                parent_unit_id = Number(unit_id)
                            }
                            if (unitDataObject.hasOwnProperty('contigent') && unitDataObject.contigent) {
                                const contingentUnitIds = Object.keys(unitDataObject.contigent)
                                if (contingentUnitIds.length > 0) {
                                    contingentUnitIds.forEach(contingentUnitId => {
                                        if (Number(contingentUnitId) === Number(target_unit_id)) {
                                            parent_unit_id = Number(unit_id)
                                        }
                                    })

                                    if (parent_unit_id !== null && parent_unit_id === Number(unit_id)) {
                                        contingentUnitIds.forEach(contingentUnitId => {
                                            unit_list.push(Number(contingentUnitId))
                                        })
                                    }
                                }
                            }
                        }
                    }
                })
            }
            return {
                parent_unit: parent_unit_id,
                child_unit_list: unit_list
            }
        }
    },
    mounted() {
        let request = {
            context: {
                company: this.$store.getters['user/getCompany']
            },
            data: []
        }
        request.data.push({
            uid: C_RESERVATION_ACCOMMODATION_BOOKING_SITE,
            function: C_RESERVATION_ACCOMMODATION_BOOKING_SITE
        })
        fetchAccessControlData(AC_FEATURE_SETUP, request).then(response => {
            this.show_channel_data = response.data[C_RESERVATION_ACCOMMODATION_BOOKING_SITE].visible
        })

        let storagePresets = localStorage.getItem(LS_PRESETS)
        if (storagePresets) {
            storagePresets = JSON.parse(storagePresets)
        } else {
            getPresets().then(response => {
                localStorage.setItem(LS_PRESETS, JSON.stringify(response.data))
                storagePresets = response.data
            })
        }
        if (storagePresets && typeof storagePresets === 'object') {
            if (storagePresets.hasOwnProperty('properties')) {
                this.property_count = storagePresets.properties
            }
            if (storagePresets.hasOwnProperty('properties_with_units')) {
                this.property_unit_count = storagePresets.properties_with_units
                if (this.property_unit_count === 1) {
                    const isContigent = 0 // this.display_work_mode === SINGLE_UNIT ? 1 : 0
                    this.showPropertyItem = false
                    this.loading = false
                    getUnitList({limit: 1000, contigent: isContigent, order_by: this.filter.sortBy}).then(res => {
                        this.unitList = res.data.items
                    }).finally(() => this.loading = false)
                }
                if (this.property_unit_count > 0 && this.property_unit_count <= DEFAULT_PAGINATION_LIMIT) {
                    this.property = null
                    this.preloadPropertyList = true

                    getPropertyList({company_id: this.company.id, has_units: 1}).then(response => {
                        this.$nextTick(() => {
                            const data = response.data && response.data.hasOwnProperty('items') ? response.data.items : []
                            if (data.length === 1) {
                                this.property = data[0]
                            }
                            this.propertyList = data.map(el => {
                                return {id: el.id, name: el.name}
                            })
                        })
                    })
                }
            }
            if (storagePresets.hasOwnProperty('root_units')) {
                this.root_unit_count = storagePresets.root_units
                this.defaultWorkMode()
                this.preloadUnitList = true
            }
            if (this.display_work_mode === MULTI_UNIT_VIEW) {
                this.dateRange.start = moment().format("YYYY-MM-DD")
                this.dateRange.end = moment().add(90, 'day').format("YYYY-MM-DD")
            } else if (this.display_work_mode === MONTHLY_VIEW) {
                this.dateRange.start = moment().startOf('month').format("YYYY-MM-DD")
                this.dateRange.end = moment(this.dateRange.start).add(this.display_month, 'months').endOf('month').format("YYYY-MM-DD")
            } else {
                this.dateRange.start = moment().set({
                    year: this.year,
                    month: this.month
                }).startOf('month').format("YYYY-MM-DD")
                this.dateRange.end = moment(this.dateRange.start).add(this.display_month, 'months').endOf('month').format("YYYY-MM-DD")
            }
            if (storagePresets.hasOwnProperty('cities')) {
                this.cities_count = storagePresets.cities
                if (storagePresets.cities <= DEFAULT_PAGINATION_LIMIT) {
                    this.getCities()
                    this.preloadCityList = true
                }
            }
            if (this.property_count === 1 && this.root_unit_count === 0) {
                getPropertyList({company_id: this.company.id}).then(response => {
                    const items = response.data && response.data.hasOwnProperty('items') ? response.data.items : []
                    if (items && items.length === 1) {
                        this.property = items.find(property => true)
                    }
                })
            }
        }

        window.addEventListener('keydown', this.shiftKeyDown)
        window.addEventListener('keyup', this.shiftKeyUp)
        window.addEventListener('resize', this.onResizeHandler)

        EventBus.$on(GE_CALENDAR_OPEN_RESERVATIONS, (reservation) => {
            this.open_edit_res = false
            this.aside_reservation = false
            fetchAccommodationReservation(reservation.id, {withOwner: true}).then(response => {
                this.reservation = response.data

                if (window.innerWidth <= MIN_WINDOW_WIDTH) {
                    this.open_edit_res = true
                } else {
                    this.aside_reservation = true
                }
            })
        })
        EventBus.$on(GE_CALENDAR_LOAD_AVAILABILITY, (data) => {
            this.fetchDataByProperty({unit: data.unitId})
        })
        EventBus.$on(GE_CALENDAR_REFRESH_DATA, (data) => {
            this.fetchDataByProperty({unit: data.unitId})
        })
        EventBus.$on(GE_CALENDAR_LOAD_PRICES, (data) => {
            this.fetchDataByProperty({unit: data.unitId})
        })

        EventBus.$on(GE_OPEN_NEW_ACC_RES_ASIDE, (data) => {
            this.openNewReservation(data)
        })
        EventBus.$on(GE_CALENDAR_ADVANCED_FILTER, () => {
            this.advanced_filter = true
        })
        EventBus.$on(GE_CALENDAR_LOAD_RESTRICTIONS, (data) => {
            this.fetchDataByProperty({unit: data.unitId})
        })
        EventBus.$on('ge_open_tag_mapping', (data) => {
            if (this.company.id === 1482) {
                this.tagAside = true
                this.entity_id = [data.unit]
            }
        })
        EventBus.$on(GE_CALENDAR_OPEN_AVAILABILITY_PROPERTY, (data) => {
            if (data && Object.keys(data).length > 0) {
                const property = data.hasOwnProperty('property') ? data.property : null
                let unit = data.hasOwnProperty('rootUnit') ? data.rootUnit : null
                let additional_data = this.getUnitAdditionalData(property, unit)

                if (unit && additional_data) {
                    if (this.display_work_mode === SINGLE_UNIT) {
                        unit.property_id = property.id
                        unit.unit_type = {name: unit.type}
                        unit.primary_rate_plan = additional_data.hasOwnProperty('primary_rate_plan') ? additional_data.primary_rate_plan : null
                        unit.los = additional_data.hasOwnProperty('default_los') ? additional_data.default_los : {}
                        unit.occupancy = additional_data.hasOwnProperty('default_occupancy') ? additional_data.default_occupancy : {}
                    }
                    this.aside_data.los = additional_data.default_los ? [additional_data.default_los] : []
                    this.aside_data.occupancy = additional_data.default_occupancy ? [additional_data.default_occupancy] : []
                    this.aside_data.rate_plans = additional_data.primary_rate_plan ? [additional_data.primary_rate_plan] : []
                    this.aside_data.unit = unit
                    const request = {
                        unitId: data.unitId,
                        dateRange: data.dateRange,
                        contigentMode: data.contigentMode,
                        contigentUnit: data.contigentUnit,
                        tab: data.tab
                    }
                    this.edit_aside = true
                    setTimeout(() => {
                        EventBus.$emit(GE_CALENDAR_OPEN_AVAILABILITY_SIDEBAR, request)
                    }, 300)
                }
            }
        })
        EventBus.$on(GE_CALENDAR_SELECT_UNIT, (data) => {
            let parentUnitData = this.findParentUnitData(data.unit);
            let parent_unit_id = parentUnitData.parent_unit;
            if (data.value) {
                this.selected_unit.push({unit: data.unit, contingent_item: data.contingent_item})
                if (parent_unit_id && data.contingent_item) {
                    // select parent (if all child units are selected)
                    let parentIndex = this.selected_unit.findIndex(el => el.unit === parent_unit_id && el.contingent_item === false)
                    let selectedChildUnitList = this.selected_unit.filter(el => parentUnitData.child_unit_list.includes(el.unit) && el.contingent_item === true)
                    if (parentIndex < 0 && selectedChildUnitList.length > 0 && selectedChildUnitList.length === parentUnitData.child_unit_list.length) {
                        this.selected_unit.push({unit: parent_unit_id, contingent_item: false})
                    }
                }
            } else {
                let index = this.selected_unit.findIndex(el => el.unit === data.unit && data.contingent_item === el.contingent_item)
                if (index >= 0) {
                    this.selected_unit.splice(index, 1)
                }
                if (parent_unit_id && data.contingent_item) {
                    // unselect parent
                    let parentIndex = this.selected_unit.findIndex(el => el.unit === parent_unit_id && el.contingent_item === false)
                    if (parentIndex >= 0) {
                        this.selected_unit.splice(parentIndex, 1)
                    }
                }
            }
            this.$emit('selectUnit', this.selected_unit)
        })
        EventBus.$on(GE_CALENDAR_SELECT_ALL_UNIT, (data) => {

            if (data.value) {
                if (this.propertyAvailabilityData.length > 0) {
                    this.propertyAvailabilityData.forEach(el => {
                        if (el.hasOwnProperty('units') && Object.keys(el.units).length > 0) {
                            for (const [unit_id, unitDataObject] of Object.entries(el.units)) {
                                let index = this.selected_unit.findIndex(el => el.unit === Number(unit_id) && el.contingent_item === false)
                                if (index < 0) {
                                    this.selected_unit.push({unit: Number(unit_id), contingent_item: false})
                                }
                                if (unitDataObject.hasOwnProperty('contigent') && unitDataObject.contigent) {
                                    const contingentUnitIds = Object.keys(unitDataObject.contigent)
                                    if (contingentUnitIds.length > 0) {
                                        let rootIndex = this.selected_unit.findIndex(el => el.unit === Number(unit_id) && el.contingent_item === true)
                                        if (rootIndex < 0) {
                                            this.selected_unit.push({unit: Number(unit_id), contingent_item: true})
                                        }
                                        contingentUnitIds.forEach(contingentUnitId => {
                                            let childIndex = this.selected_unit.findIndex(el => el.unit === Number(contingentUnitId) && el.contingent_item === true)
                                            if (childIndex < 0) {
                                                this.selected_unit.push({unit: Number(contingentUnitId), contingent_item: true})
                                            }
                                        })
                                    }
                                }
                            }
                        }
                    })
                }
            } else {
                this.selected_unit = []
            }
            this.$emit('selectUnit', this.selected_unit)
        })

        EventBus.$on(GE_CALENDAR_CROSS_COMPONENT_AVAILABILITY_MIXIN_HANDLE_DRAG_CURRENT_PROP, (date) => {
            this.currentDragDate = moment(date).toISOString()
        })
        EventBus.$on(GE_CALENDAR_CROSS_COMPONENT_AVAILABILITY_MIXIN_HANDLE_DRAG_PERIOD_START, (date) => {
            this.dragPeriodStart = moment(date).toISOString()
        })
        EventBus.$on(GE_CALENDAR_CROSS_COMPONENT_AVAILABILITY_MIXIN_HANDLE_UNIT_ROW_INDEX, (value) => {
            this.clickedUnitRowIndex = value
        })
    },
    watch: {
        'property': {
            handler(value) {
                this.unitList = []
                this.selectedUnits = []
                const isContigent = 0 // this.display_work_mode === SINGLE_UNIT ? 1 : 0
                if (Array.isArray(value)) {
                    if (value) {
                        if (this.onlyAvailableUnit) {
                            this.unitList = this.availableUnitList
                        } else {
                            this.loading = false
                            getUnitList({
                                property_id: value,
                                limit: 1000,
                                contigent: isContigent,
                                order_by: this.filter.sortBy
                            }).then(res => {
                                this.unitList = res.data.items
                            }).finally(() => this.loading = false)
                        }
                    } else {
                        this.unitList = this.preloadPropertyList ? this.preloadedUnitList : []
                    }
                } else {
                    if (value && typeof value === 'object' && value.hasOwnProperty('id')) {
                        this.loading = false
                        getUnitList({
                            property_id: value.id,
                            limit: 1000,
                            contigent: isContigent,
                            order_by: this.filter.sortBy
                        }).then(res => {
                            this.unitList = res.data.items
                        }).finally(() => this.loading = false)
                    } else {
                        if (value && typeof value === 'number') {
                            this.loading = false
                            getUnitList({
                                property_id: value,
                                limit: 1000,
                                contigent: isContigent,
                                order_by: this.filter.sortBy
                            }).then(res => {
                                this.unitList = res.data.items
                            }).finally(() => this.loading = false)
                        }
                    }
                }
            }
        },
        'unitList':{
            handler(value) {
                if(value){
                    if (this.display_work_mode === SINGLE_UNIT && value.length === 1) {
                        this.fetchData({
                            start: this.dateRange.start,
                            end: this.dateRange.end,
                        }, false, true)
                    }
                }
            }
        },
        load_more: {
            handler(object) {
                if (this.display_work_mode === MULTI_UNIT_VIEW) {
                    if (object.value && object.load) {
                        if (this.first_next_date) {
                            setTimeout(() => {
                                let indexDate = this.dateArray.findIndex(el => {
                                    return el.format('YYYY-MM-DD') === this.first_next_date
                                })
                                let calculatedPosition = this.cellPixelsWide * indexDate
                                this.scrollLeftPosition = calculatedPosition
                                if (document.querySelector('.main_calendar__holder--content-scroll-monthly')) {
                                    document.querySelector('.main_calendar__holder--content-scroll-monthly').scrollLeft = calculatedPosition
                                }
                            }, 300)
                        } else if (this.first_back_date) {
                            setTimeout(() => {
                                let indexDate = this.dateArray.findIndex(el => {
                                    return el.format('YYYY-MM-DD') === this.first_back_date
                                })
                                let calculatedPosition = this.cellPixelsWide * indexDate
                                if (document.querySelector('.main_calendar__holder--content-scroll-monthly')) {
                                    this.scrollLeftPosition = calculatedPosition - document.querySelector('.main_calendar__holder--content-scroll-monthly').clientWidth
                                    document.querySelector('.main_calendar__holder--content-scroll-monthly').scrollLeft = this.scrollLeftPosition
                                } else {
                                    this.scrollLeftPosition = calculatedPosition
                                }
                            }, 300)
                        }
                    }
                }
            }
        },
        newReservationAside: {
            handler(value) {
                if (value) {
                    this.openNewReservation(this.advanced_res_request)
                }
            }
        },
        dateRange: {
            handler(date) {
                if (typeof date === 'object' && date.hasOwnProperty('start')) {
                    this.selectMonth = moment(date.start).format('YYYY-MM')
                }
            }, immediate: true,
            deep: true
        },
        display_work_mode: {
            handler(value) {
                if (value === MONTHLY_VIEW) {
                    this.dateRange.start = moment(this.dateRange.start).startOf('month').format("YYYY-MM-DD")
                    this.dateRange.end = moment(this.dateRange.start).add(this.display_month, 'months').endOf('month').format("YYYY-MM-DD")
                } else if (value === SINGLE_UNIT) {
                    this.dateRange.start = moment().set({
                        year: this.year,
                        month: this.month
                    }).startOf('month').format("YYYY-MM-DD")
                    this.dateRange.end = moment(this.dateRange.start).add(11, 'months').endOf('month').format("YYYY-MM-DD")
                } else {
                    this.dateRange.start = moment().format("YYYY-MM-DD")
                    this.dateRange.end = moment().add(90, 'day').format("YYYY-MM-DD")
                }
                this.setDateArray()
            }
        },
        display: {
            async handler(value) {
                if (this.propertyAvailabilityData.length > 0) {
                    this.$nextTick(() => {
                        if (this.advanced_filter_request === null) {
                            this.fetchData({
                                start: this.dateRange.start,
                                end: this.dateRange.end
                            }, false, true)
                        } else {
                            let req = {...this.advanced_filter_request, ...{currentPage: 1}}
                            this.searchAdvanced(req)
                        }
                    })
                }
            }
        },
        perPage: {
            handler(value) {
                if (this.advanced_filter_request === null) {
                    this.fetchData({
                        perPage: value,
                        start: this.dateRange.start,
                        end: this.dateRange.end
                    }, false, true)
                } else {
                    let req = {...this.advanced_filter_request, ...{currentPage: 1}}
                    this.searchAdvanced(req)
                }
            }
        },
        city: {
            handler(value) {
                let city = null
                if (typeof value === 'object' && value.hasOwnProperty('id')) {
                    city = value.id
                } else {
                    city = value.length > 0 ? value : null
                }
                let req = {company_id: this.company.id}
                if (city) {
                    req = {...req, ...{city}}
                }
                getPropertyList(req).then(response => {
                    this.$nextTick(() => {
                        const data = response.data && response.data.hasOwnProperty('items') ? response.data.items : []
                        if (data.length <= DEFAULT_PAGINATION_LIMIT) {

                            this.propertyList = data.map(el => {
                                return {id: el.id, name: el.name}
                            })
                        } else {
                            this.propertyList = []
                        }
                    })
                })
            }
        },
    },
    async created() {
        await this.setDateArray()
        if (this.display_work_mode !== SINGLE_UNIT) {
            this.getTagList(TAG_TYPE_CUSTOM)
            this.getTagList(TAG_TYPE_DESTINATION)
        }
        if (this.display_work_mode !== SINGLE_UNIT || (this.display_work_mode === SINGLE_UNIT && this.property_unit_count <= 1 && this.root_unit_count <= 1)) {
            this.fetchData({
                start: this.dateRange.start,
                end: this.dateRange.end,
            }, false, false)
        }
    },
    beforeDestroy() {
        // EventBus.$off(GE_CALENDAR_LOAD_AVAILABILITY)
        // EventBus.$off(GE_CALENDAR_UPDATE_AVAILABILITY_LIST)
        // EventBus.$off(GE_CALENDAR_LOAD_ADD_MONTH)
        // EventBus.$off(GE_CALENDAR_REFRESH_DATA)
        // EventBus.$off(GE_CALENDAR_LOAD_PRICES)
        // EventBus.$off(GE_CALENDAR_LOAD_RESTRICTIONS)
        // EventBus.$off(GE_CALENDAR_OPEN_AVAILABILITY_PROPERTY)
        // EventBus.$off(GE_CALENDAR_OPEN_AVAILABILITY_SIDEBAR)
        // EventBus.$off(GE_OPEN_NEW_ACC_RES_ASIDE)
        // EventBus.$off(GE_CALENDAR_SELECT_ALL_UNIT)
        // EventBus.$off(GE_CALENDAR_CROSS_COMPONENT_AVAILABILITY_MIXIN_HANDLE_DRAG_CURRENT_PROP)
        // EventBus.$off(GE_CALENDAR_CROSS_COMPONENT_AVAILABILITY_MIXIN_HANDLE_DRAG_PERIOD_START)
        // EventBus.$off(GE_CALENDAR_CROSS_COMPONENT_AVAILABILITY_MIXIN_HANDLE_UNIT_ROW_INDEX)
        window.removeEventListener("keyup", this.shiftKeyUp)
        window.removeEventListener("keydown", this.shiftKeyDown)
        window.removeEventListener("resize", this.onResizeHandler)
    }
}
</script>

<style scoped>
.res-no-border {
    box-shadow: none;
}

.res-no-border > div {
    border: none !important;
    padding-left: 0;
}

.no-border {
    border-bottom: none !important
}

.width-period {
    width: 13.5rem
}

.w-30 {
    width: 30rem;
}

.display-settings {
    width: 7.8rem;
}

.availability_calendar--load-more {
    height: 21px;
}

@media (min-width: 1200px) and  (max-width: 1450px) {
    .display {
        flex: 0 0 40%;
        max-width: 40%;
    }
}

@media (min-width: 1200px) and  (max-width: 1600px) {
    .display-multi-unit {
        flex: 0 0 30%;
        max-width: 30%;
    }
}
</style>

