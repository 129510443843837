<template functional>
    <div
        class="availability_calendar--cell position-relative availability_calendar--availability-cell"
        :class="{'availability_calendar__bg-hover': props.highlighted && !props.cellData.reservation, 'availability_calendar__bg-hover_note':props.cellData.note_highlight.value, ...props.cellData.lastDayOfMonthClass}"
        :style="{'grid-column': props.cellData.column + '/ span ' + props.cellData.colspan}">
        <div v-if="props.cellData.reservation"
             :class="{
                'availability_calendar_reservation_before':props.cellData.hasReservationOnDayBefore && props.cellData.column > 1,
                'availability_calendar_reservation':!props.cellData.hasReservationOnDayBefore && props.cellData.column > 1,
                'availability_calendar_first-column':props.cellData.column === 1,
                'availability_calendar_first-day': props.cellData.column === 1 && !props.isCheckIn && !props.isFirstDayOfFirstMonth}"
             >
            <div :style="`width: ${props.cellData.widthGuest}rem;`"
                 :id="`popover-reservation-${props.cellData.reservation.id}-${props.cellData.date.format('YYYY-MM-DD')}`"
                 class="availability_calendar--status availability_calendar--background_primary availability_calendar--status-end"
                 :class="{'availability_calendar--status-start ' : props.isCheckIn || props.isFirstDayOfFirstMonth,}"
                 @click="listeners.showReservation"
                 @mouseenter.stop="listeners.mouseEnter"
                 @mouseleave="listeners.mouseLeave"
            >
                <div v-if="props.isCheckIn || props.isFirstDayOfFirstMonth" class="availability_calendar-guest--icon">
                    <b-img fluid :src="props.cellData.reservation.channel_icon"></b-img>
                </div>
                <div class=availability_calendar-guest--name
                     :style="`width:${(props.cellData.widthGuest-4) > 0.78 ? props.cellData.widthGuest-4 :  props.cellData.hasReservationOnDayBefore ? 0 : props.cellData.widthGuest - 4}rem`">

                    {{ props.cellData.reservation.formatted_name }}
                </div>
                <div v-if="props.cellData.reservation.tags" class="mr-1 mt-1">
                    <i class="fa fa-tag" style="color: white;"></i>
                </div>
            </div>

            <b-popover
                v-if="props.cellData.showResPopup && props.cellData.showResPopup.show"
                container="my-container_calendar"
                class="calendar-popover"
                :show.sync="props.cellData.showResPopup.show"
                :no-fade="true"
                placement="left"
                :target="`popover-reservation-${props.cellData.reservation.id}-${props.cellData.date.format('YYYY-MM-DD')}`">
                <template v-slot:title>{{props.cellData.reservation.formatted_check_in_date}} - {{props.cellData.reservation.formatted_check_out_date}}
                </template>
                <b-list-group>
                    <p class="d-flex justify-content-between align-items-center">
                        {{props.cellData.translations.RESERVATION_CODE }}: {{ props.cellData.reservation.distribution_booking_id }}
                    </p>
                    <p v-if="props.show_channel_data" class="d-flex justify-content-between align-items-center">
                        {{props.cellData.translations.SALES_CHANNEL}}: {{ props.cellData.reservation.channel.name }}
                    </p>
                    <p class="d-flex justify-content-between align-items-center">
                        {{ props.cellData.translations.GUEST }}: {{ props.cellData.reservation.first_name }} {{ props.cellData.reservation.last_name }}
                    </p>
                    <p class="d-flex justify-content-between align-items-center">
                        {{ props.cellData.translations.ADULTS }} +18 / {{ props.cellData.translations.CHILDREN }} 3-18 / {{ props.cellData.translations.BABIES }} 0-3:
                        {{ props.cellData.reservation.adult_no }} / {{ props.cellData.reservation.child_no ? props.cellData.reservation.child_no : 0}} /
                        {{ props.cellData.reservation.baby_no ? props.cellData.reservation.baby_no : 0 }}
                    </p>
                    <p class="d-flex justify-content-between align-items-center">
                        {{ props.cellData.translations.TOTAL }}: {{ props.cellData.reservation.channel_total }}
                    </p>
                    <p v-if="props.cellData.reservation.check_in_time"
                       class="d-flex justify-content-between align-items-center">
                        {{ props.cellData.translations.ARRIVAL_TIME }}: {{ props.cellData.reservation.formatted_check_in_time}}
                    </p>
                    <p class="d-flex justify-content-between align-items-center">
                        {{ props.cellData.translations.BOOKED_AT }}: {{ props.cellData.reservation.formatted_created_date }}
                    </p>
                    <p class="d-flex justify-content-between align-items-center">
                        {{ props.cellData.translations.TAG }}: {{ props.cellData.reservation.tags }}
                    </p>
                </b-list-group>
            </b-popover>


        </div>
        <template v-else>
            <b-popover
                v-if="props.cellData.column && props.cellData.note_highlight && props.cellData.note_highlight.key && props.cellData.note && props.cellData.showNotePopup.show"
                container="my-container_calendar_note"
                class="calendar-popover"
                :show.sync="props.cellData.showNotePopup.show"
                :no-fade="true"
                placement="left"
                :target="`popover-note-${props.cellData.note_highlight.key}-${props.cellData.column}`">
                <template v-slot:title> {{ props.cellData.note.period_start_foramtted }} - {{ props.cellData.note.period_end_foramtted }}</template>
                <b-list-group>
                    <p class="d-flex justify-content-between align-items-center">
                        {{props.cellData.translations.NOTE}}: {{props.cellData.note.note}}
                    </p>
                    <p>{{props.cellData.translations.USER}}: {{props.cellData.note.user_first_name}} {{props.cellData.note.user_last_name}}</p>
                    <p class="d-flex justify-content-between align-items-center">
                        {{props.cellData.translations.CLOSED_AT}}: {{props.cellData.note.timestamp_formatted}}
                    </p>
                </b-list-group>
            </b-popover>
            <div v-if="Number(props.cellData.room_status) === 1 && !props.cellData.unit_data.availability.available && props.cellData.isRoot"
                 class="justify-content-end pb-4" style="font-size: 0.2rem">
                <i class="fa fa-circle mr-1 main_calendar_test_indicator-open"></i>
            </div>
            <div v-if="props.cellData.unit_data.availability.available && Number(props.cellData.room_status) === 0 && props.cellData.isRoot"
                 class="justify-content-start pb-4 ml-1">
                <i class="fa fa-circle mr-1 availability_calendar_indicator-open " style="font-size: 0.2rem"></i>
            </div>
            <div
                :id="props.cellData.column && props.cellData.note_highlight && props.cellData.note_highlight.key ? `popover-note-${props.cellData.note_highlight.key}-${props.cellData.column}` : ''"
                @click.shift.exact="listeners.drag"
                @click.exact="listeners.startEndDrag"
                @mouseover.shift="listeners.currentDrag"
                @mouseenter.stop="listeners.mouseEnterNote"
                @mouseleave="listeners.mouseLeaveNote"
                class="justify-content-center main_calendar--status"
            >
                <span v-if="!props.cellData.isRoot">
                     <i v-if="!props.cellData.unit_data.availability.available "class="fa fa-circle availability_calendar_indicator-close"></i>
                     <i v-else class="fa fa-circle availability_calendar_indicator-open"></i>
                </span>
                <span v-else>
               <template v-if="props.cellData.unit_data.availability.available && Number(props.cellData.room_status) === 1">
                <span v-if="props.cellData.display === 0 && props.cellData.unit_data.hasOwnProperty('default_rates')">
                  {{ props.cellData.unit_data.default_rates }}
                </span>
                <span v-else-if="props.cellData.display === 1 && props.cellData.hasOwnProperty('min_stay') && props.cellData.min_stay">
                  {{ props.cellData.min_stay }}
                </span>
                    <i  v-else class="fa fa-circle availability_calendar_indicator-open"></i>
                </template>

                <i v-else class="fa fa-circle availability_calendar_indicator-close"></i>
                </span>
            </div>

        </template>
    </div>
</template>

<script>

export default {
    name: "PropertyCalendarItemCellTest",
    props: {
        cellData: {
            type: Object,
        },
        noteHighLight:{
            type:Object
        },
        highlighted: {
            type: Boolean
        },
        show_channel_data: {
            type: Boolean,
            default: false
        },
        isCheckIn: {
            type: Boolean,
            default: false
        },
        isFirstDayOfFirstMonth: {
            type: Boolean,
            default: false
        },
    }

}
</script>

<style>


.bs-popover-left .arrow {
    display: none;
}

.bs-popover-right .arrow {
    display: none;
}

</style>






