<template>
    <div>
        <b-row align-h="between">
            <b-col cols="4" class="mb-1">
                <table class="table table-hover">
                    <tbody>
                    <tr>
                        <td>{{ $t("MESSAGE_NUMBER") }}</td>
                        <td class="text-right">{{ paymentMessageData.message_number }}
                        </td>
                        <td class="text-left"
                            v-if="checkPermission(C_FINANCE_PAYMENT_MESSAGES_MESSAGE_NUMBER_E)">
                            <app-button
                                @click="editPaymentMessageNumber"
                                icon="edit"
                                :show_text="false"
                                variant="link">
                            </app-button>
                        </td>
                    </tr>
                    <tr>
                        <td>{{ $t("ITEM_COUNT") }}</td>
                        <td class="text-right">{{ paymentMessageData.item_number }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t("CONNECTED_RESERVATIONS") }}</td>
                        <td class="text-right">
                            <app-button
                                :loading="loadingConnectedReservations"
                                @click="showConnectedReservations">
                                {{ $t("SHOW") }}
                            </app-button>
                        </td>
                    </tr>
                    <tr v-if="status === STATUS_INVOICE_CREATED">
                        <td>
                            <app-button @click="generateXML" :loading="generatingXML">
                                XML
                            </app-button>
                        </td>
                        <td class="text-right"></td>
                    </tr>
                    </tbody>
                </table>
            </b-col>
            <b-col cols="7" class="mb-1" v-if="status === STATUS_CREATED">
                <b-row>
                    <b-col lg="4" class="mb-4">
                        <label class="label">{{ $t('DISTRIBUTION') }}</label>
                        <app-select
                            :options="distributionList"
                            v-model="distribution">
                        </app-select>
                    </b-col>
                    <b-col lg="8" sm="12" class="mb-4" style="margin-top: 25px;">
                        <b-form-file
                            v-model="map_file"
                            multiple
                            style="width: 25.8rem;"
                            accept=".csv"
                            :placeholder="$t('UPLOAD_TEXT')"
                            :drop-placeholder="$t('DROP')"
                            :disabled="!distribution">
                        </b-form-file>
                        <app-button
                            :loading="upload_loading"
                            :disabled="!distribution"
                            inline
                            class="ml-3"
                            @click="upload">{{ $t('UPLOAD') }}
                        </app-button>
                    </b-col>
                </b-row>
                <payment-message-reservation-filter
                    @loading="loadingReservations=$event"
                    :loading="loadingReservations">
                </payment-message-reservation-filter>
            </b-col>
        </b-row>
        <b-row align-h="between" v-if="status === STATUS_CREATED">
            <b-col cols="4">
                <div class="payment-message-table-container mb-4">
                    <div class="item-table-overlay" v-if="mappingMode">
                        <div class="item-table-overlay-content">
                            <h6 class="text-white">{{ mappingItemHeader }} <br>
                                {{ this.mappingItem.client_reference }}
                            </h6>
                            <app-button @click="mappingMode=false">{{ $t('FINISH') }}</app-button>
                        </div>
                    </div>
                    <app-table-v2
                        :fields="pmItemFields"
                        :provider="paymentMessageItemProvider"
                        :search-event="PM_DATA_GET_EVENT" :paginated="false">
                        <template v-slot:payer_name="data">
                            {{ data.item.payer_name }}<br>
                            {{ data.item.client_reference }}
                        </template>
                        <template v-slot:amount_credited="data">
                            {{ data.item.amount_credited }}
                            <span v-if="paymentMessageData && paymentMessageData.hasOwnProperty('currency')">
                                {{ paymentMessageData.currency }}
                            </span>
                        </template>
                        <template v-slot:actions="data">
                            <div class="d-flex">
                                <b-tooltip class="tooltiptext" placement="top" :target="'complete_payment'+data.item.id"
                                           triggers="hover">
                                    {{ $t('COMPLETE') }}
                                </b-tooltip>
                                <b-tooltip class="tooltiptext" placement="top" :target="'map_payment'+data.item.id"
                                           triggers="hover">
                                    Map
                                </b-tooltip>
                                <app-button :id="'map_payment'+data.item.id" button_type="expand" variant="link"
                                            :show_text="false" @click="toggleMappingMode(data.item)">Map
                                </app-button>

                                <app-button :id="'complete_payment'+data.item.id" :show_text="false"
                                            button_type="complete" variant="link" class="ml-2"
                                            @click="confirmationCompleteItem(data.item)"
                                >{{ $t('COMPLETE') }}
                                </app-button>
                            </div>
                        </template>
                    </app-table-v2>
                </div>
                <app-button v-if="uncompletedItemList.length"
                            variant="danger"
                            class="pull-right"
                            @click="confirmationCompleteAll = true">
                    {{ $t('COMPLETE_ALL') }}
                </app-button>
            </b-col>
            <b-col cols="7">
                <div class="payment-message-table-container">
                    <app-table-v2
                        :fields="computedReservationFields"
                        :provider="reservationProvider"
                        :paginated="false"
                        :search-event="RESERVATION_GET_EVENT" fixed>
                        <template v-slot:source="data">
                            <template v-if="Array.prototype.includes.call(HAS_CHANNEL_DATA, data.item.distribution.id)">
                                <small class="text-muted d-block">
                                    {{ data.item.distribution.name }}
                                </small>
                                {{ data.item.channel.name }}
                            </template>

                            <template v-else-if="!data.item.distribution.id">
                                {{ data.item.channel.name }}
                            </template>
                            <template v-else>
                                {{ data.item.distribution.name }}
                            </template>
                        </template>

                        <template v-slot:period="data">
                            {{ data.item.check_in_date | date(false) }} - {{ data.item.check_out_date | date }}
                        </template>

                        <template v-slot:guest="data">
                            {{ data.item.guest.name }}
                        </template>

                        <template v-slot:total="data">
                            <small class="text-muted d-block"
                                   v-if="company.main_currency.id !== data.item.channel_currency.id">
                                {{ data.item.channel_total }}
                            </small>
                            {{ data.item.company_total }}
                        </template>

                        <template v-slot:actions="data">
                            <app-button
                                variant="danger"
                                @click="connectReservation(data.item)"
                                :loading="connecting">
                                {{ $t('CONNECT') }}
                            </app-button>
                        </template>
                    </app-table-v2>
                </div>
            </b-col>
        </b-row>
        <b-row v-else-if="status === STATUS_COMPLETED">
            <b-col>
                <app-button class="mb-3 pull-right" icon="new" variant="danger" :loading="creatingInvoice"
                            @click="createInvoice">{{ $t('INVOICE') }}
                </app-button>
                <payment-message-invoice-list-table
                    :tableData="paymentMessageInvoiceList"></payment-message-invoice-list-table>
            </b-col>
        </b-row>
        <b-row v-else-if="status === STATUS_INVOICE_CREATED">
            <b-col>
                <invoices-list-table :search-criteria="{payment_message: paymentMessageId}"
                                     search-on-create></invoices-list-table>
            </b-col>
        </b-row>
        <app-aside :widths="['col-lg-3 col-sm-5', '40%']"
                   v-model="paymentMessageNumberSideFormState">
            <template slot="header">{{ $t('MESSAGE_NUMBER') }}</template>
            <payment-message-number
                :payment-message-data="paymentMessageData"
                @paymentNumberSaved="paymentNumberSaved">
            </payment-message-number>
        </app-aside>
        <app-aside v-model="connectedReservationsAsideState" :widths="['col-lg-6', 'col-sm-12', '50%']">
            <b-table :items="connectedReservationList" :fields="reservationFields" fixed>
                <template v-slot:source="data">
                    <template v-if="Array.prototype.includes.call(HAS_CHANNEL_DATA, data.item.distribution.id)">
                        <small class="text-muted d-block">
                            {{ data.item.distribution.name }}
                        </small>
                        {{ data.item.channel.name }}
                    </template>

                    <template v-else-if="!data.item.distribution.id">
                        {{ data.item.channel.name }}
                    </template>
                    <template v-else>
                        {{ data.item.distribution.name }}
                    </template>
                </template>

                <template v-slot:period="data">
                    {{ data.item.check_in_date | date(false) }} - {{ data.item.check_out_date | date }}
                </template>

                <template v-slot:guest="data">
                    {{ data.item.guest.first_name }} {{ data.item.guest.last_name }}
                </template>

                <template v-slot:total="data">
                    <small class="text-muted d-block"
                           v-if="company.main_currency.id !== data.item.channel_currency.id">
                        {{ data.item.channel_total_formatted }}
                    </small>
                    {{ data.item.company_total_formatted }}
                </template>

                <template v-slot:actions="data">
                    <app-button
                        v-if="status !== STATUS_INVOICE_CREATED"
                        variant="danger"
                        @click="disconnectReservation(data.item)"
                        :loading="connecting">
                        {{ $t('DISCONNECT') }}
                    </app-button>
                </template>
            </b-table>
        </app-aside>
        <app-aside v-model="autoMapAsideState" :widths="['col-lg-12']" full-width>
            <template v-slot:header>
                Autoconnect
            </template>
            <template v-if="autoMapSuggestionList.length">
                <b-table :items="autoMapSuggestionList" :fields="autoMapReservationFields">
                    <template v-slot:HEAD_checkbox="data">
                        <div v-if="autoMapSuggestionList.length > 0" class="tableSelectableCheckbox">
                            <b-checkbox v-model="selectAllSuggestionsValue"
                                        @change="selectAllSuggestions"
                                        size="sm" :inline="true">
                            </b-checkbox>
                        </div>
                    </template>
                    <template v-slot:checkbox="data">
                        <div class="tableSelectableCheckbox">
                            <b-checkbox v-model="data.item.checked"
                                        size="sm" @change="verifySuggestionSelectAllValue"
                                        :inline="true">
                            </b-checkbox>
                        </div>
                    </template>
                    <template v-slot:payment_message_item.payer_name="data">
                        {{ data.item.payment_message_item.payer_name }}<br>
                        {{ data.item.payment_message_item.client_reference }}
                    </template>
                    <template v-slot:amount="data">
                        <span
                            v-if="data.item.payment_message_item.hasOwnProperty('amount_credited') && data.item.payment_message_item.hasOwnProperty('currency')">
                            {{ data.item.payment_message_item.amount_credited }} {{ data.item.payment_message_item.currency }}
                        </span>
                    </template>
                    <template v-slot:period="data">
                        {{ data.item.reservation.check_in | date(false) }} -
                        {{ data.item.reservation.check_out | date }}
                    </template>

                    <template v-slot:guest="data">
                        {{ data.item.reservation.customer.first_name }} {{ data.item.reservation.customer.last_name }}
                    </template>
                    <template v-slot:company_total="data">
                        <small class="text-muted d-block"
                               v-if="data.item.reservation.hasOwnProperty('channel_currency') && company.main_currency.id !== data.item.reservation.channel_currency.id">
                            {{ data.item.reservation.channel_total }}
                        </small>
                        {{ data.item.reservation.company_total }}
                    </template>
                </b-table>
                <b-row>
                    <b-col>
                        <app-button @click="autoMapSuggestions" :loading="mapping"
                                    :disabled="!autoMapSuggestionList.some(el => el.checked)">{{ $t('SAVE') }}
                        </app-button>
                    </b-col>
                </b-row>
            </template>
        </app-aside>
        <app-confirmation-dialog :title="$t('COMPLETE_CONFIRMATION')" @confirm="completeItem()"
                                 :show="confirmationComplete" @cancel="confirmationComplete=false">
            {{ $t('COMPLETE_CONFIRMATION_DIALOG') }}
        </app-confirmation-dialog>
        <app-confirmation-dialog :title="$t('COMPLETE_CONFIRMATION')" @confirm="completeAllItems"
                                 :show="confirmationCompleteAll" @cancel="confirmationCompleteAll=false">
            {{ $t('COMPLETE_ALL_CONFIRMATION_DIALOG') }}
        </app-confirmation-dialog>
    </div>
</template>

<script>
import {
    C_FINANCE_PAYMENT_MESSAGES_MESSAGE_NUMBER_E
} from "@/shared/component_permission";
import {
    autoMapFile,
    completePaymentMessageItems, createPaymentMessageInvoice,
    fetchPaymentMessage, generatePaymentMessageXML,
    getMappedPaymentMessageReservations, getPaymentMessageAutoMapSuggestions,
    getPaymentMessageInvoiceList,
    getPaymentMessageReservationList,
    mapReservationToPaymentMessageItem,
    unmapReservationFromPaymentMessageItem
} from "@/services/finance/payment_message";
import AppTableV2 from "@/components/app/AppTableV2";
import AppButton from "@/components/app/AppButton/AppButton";
import {EventBus, GE_LOADER_HIDE, GE_LOADER_SHOW} from "@/shared/EventBus";
import PaymentMessageReservationFilter from "@/components/finance/payment_message/PaymentMessageReservationFilter";
import {cloneDeep} from "lodash";
import {notifySuccess, toast} from "@/shared/plugins/toastr";
import AppAside from "@/components/app/form/AppAside";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import PaymentMessageInvoiceListTable from "@/components/finance/payment_message/PaymentMessageInvoiceListTable";
import InvoicesListTable from "@/components/finance/invoices/InvoiceList";
import {AIRBNB, BOOKING, HAS_CHANNEL_DATA} from "@/shared/constants";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import PaymentMessageNumber from "@/components/finance/payment_message/PaymentMessageNumber";

const STATUS_CREATED = 12
const STATUS_COMPLETED = 13
const STATUS_INVOICE_CREATED = 14

export default {
    name: "PaymentMessage",
    components: {
        AppSelect,
        AppConfirmationDialog,
        InvoicesListTable,
        PaymentMessageInvoiceListTable,
        PaymentMessageReservationFilter,
        AppButton,
        AppTableV2,
        AppAside,
        PaymentMessageNumber
    },
    mixins: [getErrorMessage],
    props: {
        paymentMessageId: {
            type: Number,
            required: true
        },
        paymentMessageStatus: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            C_FINANCE_PAYMENT_MESSAGES_MESSAGE_NUMBER_E,
            paymentMessageNumberSideFormState: false,
            STATUS_CREATED,
            STATUS_COMPLETED,
            STATUS_INVOICE_CREATED,
            pmItemFields: [
                {key: 'number', label: '#'},
                {key: 'payer_name', label: this.$t('NAME_PAYMENT_ID')},
                {key: 'amount_credited', label: this.$t('AMOUNT'), class: "ws-no-wrap"},
                {key: 'actions', label: '', class: 'text-right ws-no-wrap'},
            ],
            reservationFields: [
                {key: 'id', label: 'ID'},
                {key: 'reservation_code', label: this.$t('RESERVATION_CODE')},
                {key: 'source', label: this.$t('SALES_CHANNEL')},
                {key: 'status.name', label: this.$t('STATUS')},
                {key: 'period', label: this.$i18n.t('PERIOD'), tdClass: ''},
                {key: 'guest', label: this.$i18n.t('GUEST')},
                {key: 'owner.name', label: this.$i18n.t('OWNER')},
                {key: 'total', label: this.$i18n.t('TOTAL'), class: 'text-right ws-no-wrap'},
                {key: 'actions', label: ''},
            ],
            autoMapReservationFields: [
                {key: 'checkbox', label: ''},
                {key: 'payment_message_item.number', label: '#'},
                {
                    key: 'payment_message_item.payer_name',
                    label: this.$t('NAME_PAYMENT_ID'),
                    thStyle: "width:25%",
                    tdStyle: "25%"
                },
                {key: 'amount', label: this.$t('AMOUNT')},
                {key: 'reservation.distribution_booking_id', label: this.$t('RESERVATION_CODE')},
                {key: 'period', label: this.$i18n.t('PERIOD')},
                {key: 'guest', label: this.$i18n.t('GUEST')},
                {key: 'reservation.status.label', label: this.$t('STATUS')},
                {key: 'reservation.owner.name', label: this.$i18n.t('OWNER')},
                {key: 'company_total', label: this.$t('TOTAL')},
            ],
            PM_DATA_GET_EVENT: 'getPaymentItems',
            RESERVATION_GET_EVENT: 'getReservations',
            itemCBState: false,
            mappingItem: null,
            mappingMode: false,
            reservationFilter: {},
            paymentMessageData: {},
            connectedReservationList: [],
            reservationList: [],
            paymentMessageInvoiceList: [],
            connectedReservationsAsideState: false,
            autoMapAsideState: false,
            autoMapSuggestionList: [],
            selectAllSuggestionsValue: true,
            loadingReservations: false,
            connecting: false,
            mapping: false,
            completing: false,
            creatingInvoice: false,
            generatingXML: false,
            disconnectCount: 0,
            HAS_CHANNEL_DATA,
            map_file: [],
            upload_loading: false,
            confirmationComplete: false,
            confirmationCompleteAll: false,
            loadingConnectedReservations: false,
            distribution: null,
            distributionList: [
                {
                    id: AIRBNB,
                    name: 'AirBnb',
                },
                {
                    id: BOOKING,
                    name: 'Booking.com'
                },
            ],
        }
    },
    computed: {
        mappingItemHeader() {
            return this.mappingItem ? `#${this.mappingItem.number} ${this.mappingItem.payer_name}` : ''
        },
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        computedReservationFields() {
            return this.mappingMode
                ? this.reservationFields
                : this.reservationFields.filter(field => field.key !== 'actions')
        },
        uncompletedItemList() {
            return this.paymentMessageData && this.paymentMessageData.items
                ? this.paymentMessageData.items.filter(item => !item.completed) : []
        },
        status() {
            return this.paymentMessageData && this.paymentMessageData.status
                ? this.paymentMessageData.status.id : null
        }
    },
    watch: {
        connectedReservationsAsideState(value) {
            if (!value && this.disconnectCount > 0) {
                if (this.status !== STATUS_CREATED) {
                    this.paymentMessageData.status.id = STATUS_CREATED
                }
                this.paymentMessageData.items = []
                this.getPaymentMessageData()
            }
        }
    },
    methods: {
        editPaymentMessageNumber() {
            this.paymentMessageNumberSideFormState = true
        },
        paymentNumberSaved(data) {
            this.paymentMessageData = data
            EventBus.$emit(GE_LOADER_SHOW)
            setTimeout(() => {
                EventBus.$emit(GE_LOADER_HIDE)
            }, 1000)
            this.paymentMessageNumberSideFormState = false
            this.$emit('updateMessageNumber')
        },
        confirmationCompleteItem(item) {
            this.editItem = item
            this.confirmationComplete = true
        },
        upload() {
            this.upload_loading = true
            let formData = new FormData();

            for (const file of this.map_file) {
                formData.append("map_file[]", file);
            }

            formData.append('distribution', this.distribution)
            autoMapFile(this.paymentMessageId, formData).then(response => {
                this.autoMapSuggestionList = response.data.map(item => ({...item, checked: item.auto_map}))
                if (this.autoMapSuggestionList.length) {
                    this.autoMapAsideState = true
                } else {
                    toast({message: this.$t('NO_RESULTS_FOUND'), type: 'warning'})
                }
                notifySuccess()
            }, error => {
                this.showErrorMessages(error)
            }).finally(() => this.upload_loading = false)
        },
        getPaymentMessageInvoiceList() {
            getPaymentMessageInvoiceList(this.paymentMessageId).then(response => {
                this.paymentMessageInvoiceList = response.data
            }, error => this.showErrorMessages(error))
        },
        getPaymentMessageData() {
            fetchPaymentMessage(this.paymentMessageId).then(response => {
                this.paymentMessageData = response.data
                this.$nextTick(() => {
                    if (this.status === STATUS_COMPLETED) {
                        this.getPaymentMessageInvoiceList()
                    } else {
                        EventBus.$emit(this.PM_DATA_GET_EVENT)

                    }
                })
            })
        },
        paymentMessageItemProvider() {
            return Promise.resolve({data: this.uncompletedItemList})
        },
        reservationProvider(req) {
            if (this.reservationList.length) {
                return Promise.resolve({
                    data: this.reservationList.filter(el => !this.connectedReservationList.map(el => el.id).includes(el.id))
                })
            } else {
                return getPaymentMessageReservationList({
                    payment_message: this.paymentMessageId, ...this.reservationFilter, limit: 999,
                }).then(response => {
                    this.reservationList = response.data.items
                    return {
                        data: response.data.items
                    }
                }).finally(() => this.loadingReservations = false)
            }
        },
        toggleMappingMode(item) {
            this.mappingMode = true
            this.mappingItem = item
        },
        connectReservation(reservation) {
            this.connecting = true
            const items = [{item: this.mappingItem.id, reservation: reservation.id}]
            mapReservationToPaymentMessageItem(this.paymentMessageId, {items})
                .then(response => {
                    notifySuccess()
                    if (response.data.length === 1) {
                        this.connectedReservationList.push({id: response.data[0].reservation})
                    }

                    EventBus.$emit(this.RESERVATION_GET_EVENT)
                }, error => {
                    this.showErrorMessages(error)
                }).finally(() => this.connecting = false)
        },
        showConnectedReservations() {
            this.disconnectCount = 0
            this.loadingConnectedReservations = true
            getMappedPaymentMessageReservations(this.paymentMessageId).then(response => {
                this.connectedReservationList = response.data
                if (this.connectedReservationList.length) {
                    this.connectedReservationsAsideState = true
                } else {
                    toast({message: this.$t('NO_RESULTS_FOUND'), type: 'warning'})
                }
            }, error => this.showErrorMessages(error)).finally(() => this.loadingConnectedReservations = false)
        },
        disconnectReservation(reservation) {
            this.connecting = true
            unmapReservationFromPaymentMessageItem(this.paymentMessageId, reservation.id)
                .then(() => {
                    notifySuccess()
                    this.disconnectCount++
                    this.connectedReservationList.splice(this.connectedReservationList.findIndex(x => x.id === reservation.id), 1)
                    if (!this.connectedReservationList.length) {
                        this.connectedReservationsAsideState = false
                    }
                }, error => {
                    this.showErrorMessages(error)
                }).finally(() => this.connecting = false)
        },
        selectAllSuggestions(value) {
            for (let item of this.autoMapSuggestionList) {
                item.checked = value
            }
        },
        verifySuggestionSelectAllValue() {
            this.$nextTick(() => {
                this.selectAllSuggestionsValue = this.autoMapSuggestionList.reduce((acc, item) => {
                    return acc && item.checked;
                }, true)
            })
        },
        showAutoMapSuggestions() {
            this.autoMapSuggestionList = []
            EventBus.$emit('loaderAutoMap', true)
            getPaymentMessageAutoMapSuggestions(this.paymentMessageId, this.reservationFilter).then(response => {
                this.autoMapSuggestionList = response.data.map(item => ({...item, checked: item.auto_map}))
                if (this.autoMapSuggestionList.length) {
                    this.autoMapAsideState = true
                } else {
                    toast({message: this.$t('NO_RESULTS_FOUND'), type: 'warning'})
                }
            }, error => this.showErrorMessages(error)).finally(() => {
                this.loadingReservations = false
                EventBus.$emit('loaderAutoMap', false)
            })
        },
        autoMapSuggestions() {
            const items = this.autoMapSuggestionList
                .filter(el => el.checked)
                .map(el => ({item: el.payment_message_item.id, reservation: el.reservation.id, complete: true}))

            this.mapping = true
            mapReservationToPaymentMessageItem(this.paymentMessageId, {items})
                .then(() => {
                    notifySuccess()
                    this.reservationList = []
                    this.paymentMessageData.items = []
                    this.getPaymentMessageData()
                    this.autoMapAsideState = false
                }, error => this.showErrorMessages(error))
                .finally(() => this.mapping = false)
        },
        completeItem() {
            this.completing = true
            completePaymentMessageItems(this.paymentMessageId, this.editItem.id).then(() => {
                notifySuccess()
                this.paymentMessageData.items.splice(this.paymentMessageData.items.findIndex(x => x.id === this.editItem.id), 1)
                this.getPaymentMessageData()
            }, error => this.showErrorMessages(error)).finally(() => {
                this.completing = false
                this.confirmationComplete = false
            })
        },
        completeAllItems() {
            this.completing = true
            const items = this.paymentMessageData.items
                .filter(item => !item.completed)
                .map(item => item.id)

            completePaymentMessageItems(this.paymentMessageId, items).then(() => {
                notifySuccess()
                this.paymentMessageData.items = []
                this.getPaymentMessageData()
            }, error => this.showErrorMessages(error)).finally(() => {
                this.completing = false
                this.confirmationCompleteAll = false
            })
        },
        createInvoice() {
            this.creatingInvoice = true
            const invoiceList = this.paymentMessageInvoiceList.reduce((acc, el) => {
                acc = acc.concat(el.invoice_list)
                return acc
            }, [])
            createPaymentMessageInvoice(this.paymentMessageId, invoiceList).then(() => {
                notifySuccess()
                this.paymentMessageData.status.id = STATUS_INVOICE_CREATED
            }, error => this.showErrorMessages(error)).finally(() => this.creatingInvoice = false)
        },
        generateXML() {
            this.generatingXML = true
            generatePaymentMessageXML(this.paymentMessageId).then(response => {
                let blob = new Blob([response], {type: 'text/xml'});
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `pm-${this.paymentMessageId}.xml`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }, error => this.showErrorMessages(error)).finally(() => this.generatingXML = false)
            }
    },
    mounted() {
        this.paymentMessageData.status = {id: this.paymentMessageStatus}

        this.getPaymentMessageData()
        EventBus.$on('searchReservations', filter => {
            this.reservationFilter = cloneDeep(filter)
            this.reservationList = []
            EventBus.$emit(this.RESERVATION_GET_EVENT)
        })

        EventBus.$on('searchAutoMapSuggestions', filter => {
            this.reservationFilter = cloneDeep(filter)
            this.showAutoMapSuggestions()
        })
    },
    beforeDestroy() {
        EventBus.$off('searchReservations')
        EventBus.$off('searchAutoMapSuggestions')
    }
}
</script>

<style lang="scss" scoped>

.payment-message-table-container {
    max-height: 50vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
        height: 1rem;
        width: 0.5rem;
    }

    &::-webkit-scrollbar-track {
        background-color: #e1e1e1;
    }

    &::-webkit-scrollbar-thumb {
        background: #c0c0c0;
        border-radius: 15px;
        border: none;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .item-table-overlay {
        position: absolute;
        z-index: 1337;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);

        &-content {
            position: absolute;
            width: 80%;
            display: flex;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
    &-td {
        padding: 0.9rem 0.45rem
    }
}

</style>
