<template>
    <div class="availability_sidebar d-flex align-items-center position-relative" style="line-height: 1">
        <div class="availability_sidebar-select">
            <b-form-checkbox size="sm" class="mt-2 pr-0 mr-0" v-model="selected" :unchecked-value="false" :value="true" @change="updateSelected"></b-form-checkbox>
        </div>

        <span :id="tooltipTarget"
              :class="contingent_item ||  Object.keys(unit.contigent).length > 0  ? 'availability_sidebar-contigent-item-name' : 'availability_sidebar-name main_calendar  availability_sidebar__holder--sidebar-h2 availability_calendar--poiner'">
            <div  @click="redirectToPropertyCalendar"  v-if="!contingent_item && checkPermission(R_PROPERTY_CALENDAR_DEV)"  class="mt-1 mb-1 justify-content-start  availability_calendar_numeric-indicator action_button" :style="!showPropertyItem? 'height: 0.24rem' : ''" style="font-weight: normal; margin-top: 9px !important;">
         <template v-if="showPropertyItem">
                 {{ property.name }}
         </template>

            </div>
            <div v-else  class="mt-1 justify-content-start  availability_calendar_numeric-indicator" :style="!showPropertyItem? 'height: 0.24rem' : ''" style="font-weight: normal; margin-top: 9px !important;">
         <template v-if="showPropertyItem">
                 {{ property.name }}
         </template>

            </div>
            <span v-if="checkPermission(R_PROPERTY_CALENDAR_DEV)" :class="!contingent_item ? 'action_button' : ''" @click="redirectToPropertyUnitCalendar">
                {{ shortUnitName }}
            </span>
            <span v-else>{{shortUnitName}}</span>

        </span>

        <i v-if="!contingent_item && unit.hasOwnProperty('tags') && unit.tags.length > 0"
           :id="'tooltip-target-tag-'+unit.id+'_'+dateStart()"
           @click="openTagMapping" class="fa fa-tag ml-1 mr-1 pull-right tooltip-help mb-4"></i>
        <i v-if="!contingent_item && !display"
           :id="'tooltip-target_help-'+unit.id+'_'+dateStart()"
           class=" fa fa-question-circle-o ml-1 pull-right tooltip-help  mr-2 mb-4"></i>
        <b-tooltip :target="'tooltip-target-tag-'+unit.id+'_'+dateStart()"
                   v-if="!contingent_item && unit.hasOwnProperty('tags') && unit.tags.length > 0">
            <b-list-group>
                <p class="m-0" :key="index+'_tag_'+unit.id+'_'+dateStart()" v-for="(tag,index) in unit.tags">
                    {{ tag.name }}
                </p>
            </b-list-group>
        </b-tooltip>
        <b-tooltip :target="'tooltip-target_help-'+unit.id+'_'+dateStart()"
                   v-if="!contingent_item && !display" custom-class="my-tooltip-class">
            <template v-if="!contingent_item && unit.hasOwnProperty('los') && unit.los && typeof unit.los === 'object'
             && Object.keys(unit.los).length > 1 && defaultLosLabel">
                {{$t("STAY").toLocaleLowerCase() }} {{defaultLosLabel}} /
            </template>

            {{$t("OCCUPANCY_PERSONS").toLocaleLowerCase()}} {{defaultOccupancyLabel}}
            <template v-if="showPlatformFeeDisclaimer && unit.platform_fee_set">
                <b-row>
                    <b-col>
                        {{ $t("BOOKING_FEE_CALENDAR_DISCLAIMER") }}
                    </b-col>
                </b-row>
            </template>
        </b-tooltip>
        <b-tooltip v-if="showShortName" placement="bottom" :target="tooltipTarget" triggers="hover">
            {{ contingent_item  ? unit ? unit.name : '' : unit && unit.hasOwnProperty('unit_type') && unit.unit_type ? unit.unit_type.name : ''  }}
        </b-tooltip>
    </div>


</template>

<script>
import router from "@/router";
import routeName from "@/router/routeNames";
import {EventBus, GE_CALENDAR_SELECT_UNIT} from "@/shared/EventBus";
import {R_PROPERTY_CALENDAR_DEV} from "@/shared/route_permission";
import moment from "moment";

export default {
    name: "PropertyCalendarSidebarItemTest",
    props: {
        display: {
            type: Number,
            default: 0
        },
        unit: {
            type: Object
        },
        contingent_item: {
            type: Boolean,
            default: false
        },
        property: {
            type: Object
        },
        dateRange: {
            type: Object
        },
        selectedUnits: {
            type: Array,
            default() {
                return []
            }
        },
        showPropertyItem: {
            type: Boolean,
            default: true
        },
        showPlatformFeeDisclaimer: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            shortNameCharacters: 115,
            selected: false,
            R_PROPERTY_CALENDAR_DEV,
        }
    },
    computed: {
        defaultLosLabel() {
            if (Object.keys(this.unit.los).length > 1) {
                if (!this.unit.los.max_stay) {
                    return this.unit.los.min_stay + '+'
                }
                if (this.unit.los.min_stay === this.unit.los.max_stay) {
                    return this.unit.los.min_stay
                }
                if (this.unit.los.min_stay !== this.unit.los.max_stay) {
                    return this.unit.los.min_stay + '-' + this.unit.los.max_stay
                }
            }

            return null
        },
        defaultOccupancyLabel() {
            if (typeof this.unit.occupancy === "object" && Object.keys(this.unit.occupancy).length > 0) {

                if (!this.unit.occupancy.max_guests) {
                    return this.unit.occupancy.min_guests + '+'
                }
                if (this.unit.occupancy.min_guests === this.unit.occupancy.max_guests) {
                    return this.unit.occupancy.min_guests
                }
                if (this.unit.occupancy.min_guests !== this.unit.occupancy.max_guests) {
                    return this.unit.occupancy.min_guests + '-' + this.unit.occupancy.max_guests
                }
            }
            return ''
        },
        showShortName() {
            let name = this.contingent_item ? this.unit.name : this.unit.unit_type.name
            return name.length > this.shortNameCharacters
        },
        shortUnitName() {
            let name = this.contingent_item ? this.unit.name : this.unit.unit_type.name
            if (this.showShortName) {
                return name.substr(0, this.shortNameCharacters) + '...'
            } else {
                return name
            }
        },
        tooltipTarget() {
            return `tooltip_full_unit_name-${this.unit.id}`
        },
    },
    methods: {
        dateStart() {
            return moment(this.dateRange.start).startOf('month').format("YYYY-MM-DD")
        },
        openTagMapping() {
            EventBus.$emit('ge_open_tag_mapping', {unit: this.unit.id})
        },
        updateSelected(value) {
            this.selected = value
            EventBus.$emit(GE_CALENDAR_SELECT_UNIT, {unit: this.unit.id, value, contingent_item: this.contingent_item})
        },
        redirectToPropertyUnitCalendar() {
            if (this.contingent_item) {
                return
            }
            let routerData = router.resolve({
                name: routeName.RN_PROPERTY_CALENDAR,
                params: {id: this.property.id},
                query: {start: this.dateRange.start, end: this.dateRange.end, unit: this.unit.id}
            })
            window.open(routerData.href, '_blank')
        },
        redirectToPropertyCalendar() {
            let routerData = router.resolve({
                name: routeName.RN_PROPERTY_CALENDAR,
                params: {id: this.property.id},
                query: {start: this.dateRange.start, end: this.dateRange.end, all_unit: true}
            })
            window.open(routerData.href, '_blank')
        }
    },
    watch: {
        selectedUnits: {
            handler(selectedObjectList) {
                this.selected = !!selectedObjectList.find(el => el.unit === this.unit.id && el.contingent_item === this.contingent_item)
            }, immediate: true,
            deep: true
        },
        contingent_item: {
            handler(value) {
                if (!value) {
                    this.shortNameCharacters = 52
                }
            }, immediate: true
        }
    }
}
</script>

<style scoped>

</style>
