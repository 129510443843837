import http from '../http'

import {
    PRICING_LOS_EDIT_URL,
    PRICING_LOS_LIST_URL,
    PRICING_LOS_URL,
    PRICING_OCCUPANCY_LIST_URL,
    PRICING_OCCUPANCY_EDIT_URL,
    PRICING_OCCUPANCY_URL,
    PRICING_RATE_PLAN_LIST_URL,
    PRICING_RATE_PLAN_URL,
    PRICING_RATE_PLAN_EDIT_URL,
    CHARGE_PROFILE_LIST_URL,
    SALES_PERIOD_LIST_URL,
    PRP_SETUP_URL,
    RATE_MANAGER_ACQUISITION,
    UNIT_URL,
    UNIT_PRICING_URL,
    RATE_MANAGER_FILTER,
    PRP_SETUP_UNIT_PRICE_URL,
    PRICING_RATE_PLAN_COPY_URL,
    RATE_MANAGER,
    SALES_PERIOD_DELETE_URL,
    SALES_PERIOD_SETUP_URL,
    GET_BULK_DATA,
    PRICING_RATE_PLAN_LIST_COPY_URL,
    PRICING_RATE_PLAN_LIST_LINKED_VALUE_URL,
    PRICING_RATE_PLAN_UNIT_LIST_URL,
    UNIT_ID_URL, PRICING_UNIT_URL,
    PRICING_RATE_PLAN_TYPE, PRICING, CALCULATION_URL,
    PRICING_IMPORT_PRICES_URL, PRICING_DOWNLOAD_TEMPLATE_URL, PRICING_IMPORT_DAILY_PRICES_URL,
    SET_PRICING_LOS_OCCUPANCY_URL, PRICING_SETUP_VALIDATE_URL


} from '@/services/endpoints'

export function setPriceFromLosOccupancy(id, postData) {
    return http.request({
        url: SET_PRICING_LOS_OCCUPANCY_URL(id),
        method: 'post',
        data: postData
    })
}

export function getPricingLosList(searchParams) {
    return http.request({
        url: PRICING_LOS_LIST_URL,
        method: 'get',
        params: searchParams
    })
}

export function createPricingLos(postData) {
    return http.request({
        url: PRICING_LOS_URL,
        method: 'post',
        data: postData
    })
}

export function updatePricingLos(id, postData) {
    return http.request({
        url: PRICING_LOS_EDIT_URL(id),
        method: 'put',
        data: postData
    })
}

export function deletePricingLos(id, postData) {
    return http.request({
        url: PRICING_LOS_EDIT_URL(id),
        method: 'delete',
        data: postData
    })
}

export function updatePricingLosList(postData) {
    return http.request({
        url: PRICING_LOS_LIST_URL,
        method: 'post',
        data: postData
    })
}
export function pricingSetupValidate(postData) {
    return http.request({
        url: PRICING_SETUP_VALIDATE_URL,
        method: 'post',
        data: postData
    })
}

export function getPricingOccupancyList(searchParams) {
    return http.request({
        url: PRICING_OCCUPANCY_LIST_URL,
        method: 'get',
        params: searchParams
    })
}

export function createPricingOccupancy(postData) {
    return http.request({
        url: PRICING_OCCUPANCY_URL,
        method: 'post',
        data: postData
    })
}

export function updatePricingOccupancy(id, postData) {
    return http.request({
        url: PRICING_OCCUPANCY_EDIT_URL(id),
        method: 'put',
        data: postData
    })
}

export function deletePricingOccupancy(id, postData) {
    return http.request({
        url: PRICING_OCCUPANCY_EDIT_URL(id),
        method: 'delete',
        data: postData
    })
}

export function updatePricingOccupancyList(postData) {
    return http.request({
        url: PRICING_OCCUPANCY_LIST_URL,
        method: 'post',
        data: postData
    })
}

export function getPricingOccupancySuggestions(params) {
    return http.request({
        url: PRICING_OCCUPANCY_LIST_URL + "/suggestions",
        method: "get",
        params,
    })
}

export function getPricingRatePlanList(searchParams) {
    return http.request({
        url: PRICING_RATE_PLAN_LIST_URL,
        method: 'get',
        params: searchParams
    })
}

export function createPricingRatePlan(postData) {
    return http.request({
        url: PRICING_RATE_PLAN_URL,
        method: 'post',
        data: postData
    })
}

export function updatePricingRatePlan(id, postData) {
    return http.request({
        url: PRICING_RATE_PLAN_EDIT_URL(id),
        method: 'put',
        data: postData
    })
}
export function fetchPricingRatePlan(id){
    return http.request({
        url: PRICING_RATE_PLAN_EDIT_URL(id),
        method: 'get',
    })
}

export function createSysytemNonRef(postData){
    return http.request({
        url: PRICING_RATE_PLAN_URL+'-system-nrf',
        method: 'post',
        data: postData
    })
}
export function deletePricingRatePlan(id, postData) {
    return http.request({
        url: PRICING_RATE_PLAN_EDIT_URL(id),
        method: 'delete',
        data: postData
    })
}

export function getChargeProfileList(searchParams) {
    return http.request({
        url: CHARGE_PROFILE_LIST_URL,
        method: 'get',
        params: searchParams
    })
}

export function getSalesPeriodList(searchParams) {
    return http.request({
        url: SALES_PERIOD_LIST_URL,
        method: 'get',
        params: searchParams
    })
}

export function getPrpSetup(id, searchParams) {
    return http.request({
        url: PRP_SETUP_URL(id),
        method: 'get',
        params: searchParams
    })
}

export function getBulkCalendar(id,params) {
    return http.request({
        url: GET_BULK_DATA(id),
        method: 'get',
        params
    })
}
export function setBulkCalendar(id,data) {
    return http.request({
        url: GET_BULK_DATA(id),
        method: 'post',
        data
    })
}
export function setBulkCalendarBatchRateAdjust(id, data) {
    return http.request({
        url: GET_BULK_DATA(id) + '/batch-rate-adjust',
        method: 'post',
        data
    })
}
export function savePriceInPrpSetup(id, postData) {
    return http.request({
        url: PRP_SETUP_URL(id),
        method: 'put',
        data: postData
    })
}

export function getRmAcquisitionSuggestions(searchParams) {
    return http.request({
        url: RATE_MANAGER_ACQUISITION,
        method: 'get',
        params: searchParams
    })
}
export function getRmResetSuggestions(data){
  return http.request({
    url: RATE_MANAGER+"/reset",
    method: 'post',
    data
  })
}

export function getPeriodSuggestions(unit_id) {
    return http.request({
        url: UNIT_PRICING_URL(unit_id) + '/sales-period-suggestions',
        method: 'get'
    })
}

export function getRmUnitList(searchParams) {

    return http.request({
        url: UNIT_URL,
        method: 'get',
        params: searchParams
    })
}

export function setPeriodsFromSuggestion(unit_id, sales_period_setup_id) {
    return http.request({
        url: UNIT_PRICING_URL(unit_id) + '/sales-period-suggestions/' + sales_period_setup_id,
        method: 'post'
    })
}

export function updatePeriods(unit_id, data) {
    return http.request({
        url: UNIT_PRICING_URL(unit_id) + '/sales-period-list',
        method: 'put',
        data: data
    })
}
export function createPeriod(unit_id, data){
  return http.request({
    url: UNIT_PRICING_URL(unit_id) + '/sales-period',
    method:'post',
    data:data
  })
}
export function updatePeriod(unit_id, data){
  return http.request({
    url: UNIT_PRICING_URL(unit_id) + '/sales-period',
    method:'put',
    data:data
  })
}
export function deletePeriod(unit_id, id){
  return http.request({
    url:SALES_PERIOD_DELETE_URL(unit_id,id),
    method:'delete'
  })
}

export function updatePeriodList(postData) {
    return http.request({
        url: SALES_PERIOD_LIST_URL,
        method: 'post',
        params: postData
    })
}

export function updateSystemPeriodList(sales_period_setup_id, postData) {
    return http.request({
        url: SALES_PERIOD_SETUP_URL(sales_period_setup_id) + '/sales-period-list',
        method: 'post',
        params: postData
    })
}

export function getRmFilter(searchParams) {
    return http.request({
        url: RATE_MANAGER_FILTER,
        method: 'get',
        params: searchParams
    })
}

export function saveUnitPriceFromPrp(unitId, data) {
    return http.request({
        url: PRP_SETUP_UNIT_PRICE_URL(unitId),
        method: 'post',
        data: data
    })
}

export function copyRatePlans(data) {
    return http.request({
        url: PRICING_RATE_PLAN_COPY_URL,
        method: 'post',
        data: data
    })

}


export function getRmSuggestions(params = {unit: null, date_from: null, date_to: null, filter: null}) {
    return http.request({
        url: RATE_MANAGER+"/on-the-fly",
        method: 'get',
        params: params
    })

}

export function deleteRatePlanList(data) {
    return http.request({
        url: PRICING_RATE_PLAN_LIST_URL,
        method: 'delete',
        data: data
    })
}

export function copyRatePlanList(data) {
    return http.request({
        url: PRICING_RATE_PLAN_LIST_COPY_URL,
        method: 'post',
        data: data
    })
}

export function updateRatePlanListLinkedValue(data) {
    return http.request({
        url: PRICING_RATE_PLAN_LIST_LINKED_VALUE_URL,
        method: 'put',
        data: data
    })
}

export function getRatePlanListWithRestrictionsByUnit(params) {
    return http.request({
        url: PRICING_RATE_PLAN_UNIT_LIST_URL + '/restrictions',
        method: 'get',
        params
    })
}

export function validateCopyRestrictionListForRatePlansData(data) {
    return http.request({
        url: PRICING_RATE_PLAN_URL + '/restriction-list-copy-validation',
        method: 'post',
        data: data
    })
}

export function copyRestrictionListForRatePlans(data) {
    return http.request({
        url: PRICING_RATE_PLAN_URL + '/restriction-list-copy',
        method: 'post',
        data: data
    })
}
export function  getWeeklyAdjustmentPrice(unit_id, params){
    return http.request({
        url: UNIT_ID_URL(unit_id) + '/weekly-adjustment-price',
        method: 'get',
        params
    })
}
export function  saveWeeklyAdjustmentPrice(unit_id, data){
    return http.request({
        url: UNIT_ID_URL(unit_id) + '/weekly-adjustment-price',
        method: 'post',
        data
    })
}

export function setPriceLock(unit_id,data){
    return http.request({
        url: UNIT_ID_URL(unit_id)+'/set-price-lock',
        method: 'post',
        data
    })
}
export function getPriceLock(unit_id,params){
    return http.request({
        url: UNIT_ID_URL(unit_id)+'/get-price-lock',
        method: 'get',
        params
    })
}

export function getRatePlanType(){
    return http.request({
        url: PRICING_RATE_PLAN_TYPE,
        method: 'get'
    })
}

export function getRatePlanList(params) {
    return http.request({
        url: `${PRICING}/rate-plan/list`,
        method: 'get',
        params
    })
}

export function exportPricingTemplate(params, filename) {
    return http.request({
        url: PRICING_DOWNLOAD_TEMPLATE_URL + '/xls',
        responseType: 'blob',
        method: 'get',
        params
    }).then((response) => {
        let blob = new Blob([response], {type: 'application/xlsx'});

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename + `.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    })
}

export function importPricesFromFile(data) {
    return http.request({
        url: PRICING_IMPORT_PRICES_URL + '/xls',
        method: 'post',
        data: data,
    })
}

export function importDailyPricesFromFile(data) {
    return http.request({
        url: PRICING_IMPORT_DAILY_PRICES_URL + '/xls',
        method: 'post',
        data: data,
    })
}

