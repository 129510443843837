<template>
    <div v-if="isVisible" class="d-inline">
        <b-tooltip v-if="!hasPermission || hasMessage || message" :target="component_id"
                   :placement="tooltipPlacement"
                   :title="tooltip_message"></b-tooltip>
        <span :id="component_id">
        <b-button
            @click="$emit('click')"
            class="app-button-btn"
            :class="ButtonClass"
            :size="size"
            :to="to"
            :href="href"
            :target="target"
            :variant="variant"
            :disabled="isDisabled"
            v-show="v_show"
            >

            <i v-if="temp_icon !== null"
               class="mr-1" :class="temp_icon"
               aria-hidden="true">
            </i>
            <font-awesome-icon v-if="font_awesome_5" :icon="temp_icon"></font-awesome-icon>

            <slot v-if="show_text">{{$t('SAVE')}}</slot>
        </b-button>
        </span>
    </div>
</template>

<script>
    import AppValidationDescription from '@/components/app/form/AppValidationDescription'
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";

    export default {
        name: "AppButton",
        components: {
            AppValidationDescription
        },
        mixins: [AccessControlComponent],
        props: {
            id: {
                type: String,
                default: null
            },
            disabled: {
                type: Boolean,
                default: false
            },
            variant: {
                type: String,
                default: "primary"
            },
            button_type: {
                type: String,
                default: "submit"
            },
            icon: {
                type: String,
                default: null
            },
            c_permission: {
                type: Number,
                default: null
            },
            show_text: {
                type: Boolean,
                default: true
            },
            to: {
                type: Object,
                default: null
            },
            loading: {
                type: Boolean,
                default: false
            },
            size: {
                type: String,
                default: "default"
            },
            access_control_context: {
                type: Object,
                default: null
            },
            tooltipPlacement: {
                type: String,
                default: "top"
            },
            ButtonClass: {
                type: String,
                default: ""
            },
            href:{
                type: String,
                default: ""
            },
            target: {
                type: String,
                default: "_self"
            },
            message:{
                type:String,
                default:""
            },
            v_show:{
                type:Boolean,
                default:true
            },
            font_awesome_5:{
                type:Boolean,
                default:false
            }
        },
        data() {
            return {
                component_id: null,
                icons: {
                    pdf: "fa fa-file-pdf-o fa-fw",
                    excel: "fa fa fa-file-excel-o fa-fw",
                    save: "fa fa-floppy-o fa-fw",
                    edit: "fa fa-pencil-square-o fa-fw",
                    settings: "fa fa-cog fa-fw",
                    new: "fa fa-plus-custom fa-fw",
                    loading: "fa fa-spinner fa-spin fa-fw'",
                    refresh: "fa fa-refresh fa-fw",
                    switch: "fa fa-exchange fa-fw",
                    link: "fa fa-link fa-fw",
                    finance: 'fa fa-credit-card fa-fw',
                    contact: 'fa fa-address-book-o fa-fw',
                    mapping: 'fa fa-map-marker',
                    delete: 'fa fa-trash-o fa-fw',
                    align_justify: "fa fa-align-justify",
                    copy: "fa fa-copy",
                    cancel: "fa fa-ban fa-fw",
                    send: "fa fa-paper-plane-o fa-fw",
                    compress: "fa fa-compress fa-fw",
                    expand: "fa fa-expand fa-fw",
                    sign_out: "fa fa-sign-out",
                    download: "fa fa-download",
                    handshake: 'fa fa-handshake-o',
                    undo: 'fa fa-undo',
                    money: "fa fa-money",
                    exclamation_circle: "fa fa-exclamation-circle",
                    info: "fa fa-info",
                    statistics:"fa fa-line-chart",
                    minus:"fa fa-minus fa-fw",
                    plus:"fa fa-plus fa-fw",
                    slider:"sliders-h",
                    bars:"fa fa-bars",
                    key:"fa fa-key",
                    file:"fa fa-file-o ",
                    compress_arrows:"compress-arrows-alt",
                    complete:'fa fa-check-circle',
                    preview: 'fa fa-eye'
                }
            }
        },
        computed: {
            temp_icon() {
                if (this.loading) {
                    return this.icons.loading
                }

                if (this.icons[this.button_type]) {
                    return this.icons[this.button_type]
                }

                if (this.icons[this.icon]) {
                    return this.icons[this.icon]
                }

                return null
            },
            hasPermission() {
                if (this.c_permission !== null) {
                    return this.$store.getters['user/getPermission'](this.c_permission)
                }
                return true
            },
            isDisabled() {
                return this.disabled || this.loading || !this.hasPermission || this.AccessControlDisabled
            },
            isVisible() {
                return this.AccessControlVisible
            },
            hasMessage(){
                return this.AccessControlMessage ? this.AccessControlMessage[0]: null
            },
            tooltip_message(){
                if( this.message){
                    return  this.message
                } else if(this.hasMessage && this.AccessControlMessage[0].message ){
                     return    this.AccessControlMessage[0].message
                } else {
                    return this.$t('NO_PERMISSION', {value:this.c_permission})
                }

            }
        },
        created() {
            this.component_id = this.id
            if (this.component_id === null) {
                this.component_id = 'AppButton_' + this._uid
            }
        }
    }
</script>

<style scoped>
    .app-button-btn {
        white-space: nowrap;
    }
</style>
